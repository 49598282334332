import React from 'react';
import classNames from 'classnames';
import { ErrorType } from 'constants/common';

interface MessageProps {
  type?: ErrorType;
  title: string;
  children: React.ReactNode;
}

export default function SliderMessage({ type = 'error', children, title }: MessageProps) {
  const wrapperClassNames = classNames('w-full mt-3 border-l-4 text-sm p-4 slider-message', {
    'bg-background-error text-text-error border-l-border-error': type === 'error',
  });

  return (
    <div className={wrapperClassNames}>
      <h2 className="font-bold">{title}</h2>
      {children}
    </div>
  );
}
