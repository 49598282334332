import * as React from 'react';
import { classNames } from 'lib/classes';

export function SlideControl(props: {
  index: number;
  isSelected: boolean;
  onClick: (e: React.MouseEvent | React.KeyboardEvent) => void;
  label: string;
}) {
  return (
    <span
      data-testid={`indicator-${props.index}`}
      key={props.index}
      className={classNames(
        'relative border-4 rounded-full cursor-pointer inline-block',
        props.index > 0 ? 'ml-4' : ''
      )}
      style={{ borderColor: '#6E28FF', opacity: props.isSelected ? 1 : 0.2 }}
      onClick={props.onClick}
      onKeyDown={props.onClick}
      tabIndex={0}
      aria-label={`${props.label} ${props.index + 1}`}
    />
  );
}
