import Skeleton from 'react-loading-skeleton';

const CALENDAR_WIDTH = 284;
const MONTH_PICKER_HEIGHT = '44px';
const WEEKDAY_NAMES_HEIGHT = '15px';
const CALENDAR_DATES_HEIGHT = '210px';

export const ReservationCalendarSkeleton = () => (
  <div className="mx-auto" style={{ width: CALENDAR_WIDTH }}>
    <Skeleton height={MONTH_PICKER_HEIGHT} />
    <Skeleton height={WEEKDAY_NAMES_HEIGHT} />
    <Skeleton height={CALENDAR_DATES_HEIGHT} />
  </div>
);
