import cartApi from 'api/cart';
import { TFunction } from 'i18next';
import { addToCartClick } from 'modules/tracking/events/events';
import { TrackingProductList } from 'types/tracking';
import {
  BackendError,
  BareProduct,
  Cart,
  CartRecommendation,
  CartReservation,
  Variant,
} from 'types/types';

export function getQuantityOfProductInCart(product: BareProduct, cart: Cart | null): number {
  if (!product.customerBuyingLimit || !cart) {
    return 0;
  }

  let quantity = 0;
  for (const item of cart.items) {
    if (item.product.id === product.id) quantity += item.quantity;
  }

  return quantity;
}

export function canProductBeAddedToCart(product: BareProduct, cart: Cart | null): boolean {
  if (!product.customerBuyingLimit || !cart) return true;

  return getQuantityOfProductInCart(product, cart) < product.customerBuyingLimit;
}

interface Props {
  cart: Cart | null;
  product: BareProduct;
  variant: Variant;
  quantity?: number;
  setCart: (value: Cart | null) => void;
  productList: TrackingProductList | null;
  t: TFunction;
  authToken: string | null;
  reservation?: CartReservation;
  bookingExternalId?: string;
}

export async function handleAddToCart({
  cart,
  product,
  variant,
  quantity = 1,
  setCart,
  productList,
  t,
  authToken,
  reservation,
  bookingExternalId,
}: Props): Promise<void> {
  await addToCartClick({
    product,
    variant,
    productList,
    productPosition: 1,
    quantity: quantity || 1,
  });

  let currentCart = cart;

  if (!currentCart || !currentCart.tokenValue) {
    currentCart = await cartApi.fetchPickUpCart(authToken);
  }

  if (!currentCart || !currentCart.tokenValue) {
    //shouldn't happen
    return;
  }

  return new Promise(async (resolve, reject) => {
    const cartId = currentCart?.tokenValue || '';
    const recommendation: CartRecommendation | undefined = product.recommendationId
      ? {
          id: product.recommendationId,
        }
      : undefined;

    try {
      const newCart = bookingExternalId
        ? await cartApi.addBookingToCart({
            cartId,
            item: {
              productVariantCode: variant.code,
              externalBookingId: bookingExternalId,
            },
            authToken,
          })
        : await cartApi.addProductToCart({
            cartId,
            item: {
              productVariantCode: variant.code,
              quantity,
              reservation,
              recommendation,
            },
            authToken,
          });
      setCart(newCart);

      resolve();
    } catch (e) {
      if (cart) {
        cart.isLoading = false;
      }
      setCart(cart);

      let errorMessage = t('app.ui.error_body');
      if (e instanceof BackendError && e.violations.length > 0) {
        errorMessage = e.violations[0].message;
      }

      reject(errorMessage);
    }
  });
}

export default handleAddToCart;
