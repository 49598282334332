import { Variant } from 'types/product';
import { useTranslation } from 'react-i18next';
import { QuantityPickerError, QuantityPickerErrorCauses } from 'constants/product';
import { QuantityPickerErrorMessage } from 'types/errors';

export type OnMessageHandler = ({ type, message }: QuantityPickerErrorMessage) => void;

type HandleError = ({
  errorCause,
  variant,
}: {
  errorCause: QuantityPickerError;
  variant: Variant;
}) => void;

interface UseErrorHandlerProps {
  minBuyingLimit: number;
  isLoading?: boolean;
  onMessage?: OnMessageHandler;
}

export default function useQuantityErrorHandling({
  onMessage,
  minBuyingLimit,
  isLoading,
}: UseErrorHandlerProps): {
  handleError: HandleError;
} {
  const { t } = useTranslation();

  const handleError = ({
    errorCause,
    variant,
  }: {
    errorCause: QuantityPickerError;
    variant: Variant;
  }) => {
    if (!onMessage) return;

    const error: QuantityPickerErrorMessage = {
      type: 'error',
      message: t('app.ui.somethingwrong'),
    };

    switch (errorCause) {
      case QuantityPickerErrorCauses.MAX_REACHED:
        error.message = t('app.ui.quantity_variant_limit_maximum');
        break;

      case QuantityPickerErrorCauses.MIN_REACHED:
        error.message = t('app.ui.quantity_limit_minimum', {
          label: t(`app.ui.quantity.${variant.quantityLabel.toLowerCase()}`, {
            count: minBuyingLimit,
          }),
          amount: minBuyingLimit,
        });
        break;

      case QuantityPickerErrorCauses.ZERO_REACHED:
        error.message = t('app.ui.quantity_limit_reached_zero');
        break;

      case QuantityPickerErrorCauses.DISABLED:
        error.message = isLoading
          ? t('app.ui.quantity_disabled_loading')
          : t('app.ui.quantity_disabled');
        break;
    }

    onMessage(error);
  };

  return {
    handleError,
  };
}
