import { MouseEvent } from 'react';
import { Z_INDEX } from 'constants/common';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import classNames from 'classnames';

type ImageSliderArrowType = 'next' | 'prev';

interface Props {
  variant: ImageSliderArrowType;
  clickHandler: () => void;
  hasMore: boolean;
  size: 'sm' | 'lg';
  label?: string;
}

export const ImageSliderArrow = ({ variant, size, hasMore, clickHandler }: Props) => {
  if (!hasMore) return null;

  const isNext = variant === 'next';
  const ArrowIcon = isNext ? FaChevronRight : FaChevronLeft;

  const wrapperClasses = classNames(
    `cursor-pointer w-8 absolute top-0 ${Z_INDEX.level10} flex items-center justify-center h-full hover:bg-opacity-25 hover:bg-gray-300`,
    {
      'lg:w-20': size === 'lg',
      'right-0': isNext,
      'left-0': !isNext,
    }
  );

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    clickHandler();
  };

  return (
    <div className={wrapperClasses} onClick={handleClick}>
      <ArrowIcon
        style={{ stroke: 'black', strokeWidth: '5' }}
        color="white"
        size={size === 'lg' ? 48 : 24}
      />
    </div>
  );
};
