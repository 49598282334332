import { HtmlTransformer } from 'lib/HtmlTransformers/HtmlTransformerChainHandler';
import React, { ReactNode } from 'react';
import { Node } from 'lib/HtmlTransformers/Transform';

export default class TbodyTransformer implements HtmlTransformer {
  transform(node: Node, dataOrChildren: ReactNode, index: number): React.ReactElement {
    return <tbody key={`tbody${index}`}>{dataOrChildren}</tbody>;
  }

  doesTransform(node: Node): boolean {
    return node.type === 'tag' && node.name === 'tbody';
  }
}
