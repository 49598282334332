import TextAreaInput from 'components/form/TextAreaInput';
import React from 'react';
import { getComments, setComments } from 'redux/cart/reservation.slice';
import { useAppDispatch, useAppSelector } from 'redux/appStore';
import { Disclosure } from '@headlessui/react';
import { FaChevronDown as ChevronDownIcon } from 'react-icons/fa';
import { classNames } from 'lib/classes';
import { useTranslation } from 'react-i18next';

const ReservationComments = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const onCommentsChange = (value: string) => dispatch(setComments(value));
  const value = useAppSelector(getComments);

  return (
    <Disclosure as="div" className="my-4" defaultOpen={value.length > 0}>
      {({ open }) => (
        <>
          <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
            <span className="font-bold text-gray-900 text-sm">
              {t('app.ui.reservation.comments.header')}
              <span className="text-xs text-gray-500 ml-2">({t('app.ui.optional')})</span>
            </span>
            <span className="ml-6 h-7 flex items-center">
              <ChevronDownIcon
                className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                aria-hidden="true"
              />
            </span>
          </Disclosure.Button>
          <Disclosure.Panel as="div" className="mt-4">
            {open && (
              <TextAreaInput
                value={value}
                config={{}}
                extra={{ rows: 3 }}
                fieldName={'comments'}
                onChange={(e) => onCommentsChange(e.toString())}
                uniqueFieldId={'reservation-comments'}
              />
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default ReservationComments;
