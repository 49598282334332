import Skeleton from 'react-loading-skeleton';
import { classNames } from 'lib/classes';
import { useResponsive } from 'hooks/useResponsive';

interface Props {
  containerClassname?: string;
}

export const ProductBoxSkeleton = ({ containerClassname = '' }: Props) => {
  const { isMobile } = useResponsive();

  return (
    // Skeleton view
    <div className={classNames(`rounded-md shadow-default bg-white ${containerClassname}`)}>
      <div className="relative group my-4">
        <div className={classNames('w-full shadow-xl', isMobile ? 'h-[200px]' : 'h-[275px]')} />
      </div>
      <div className={classNames('px-2 py-2 sm:py-6', isMobile ? 'h-[75px]' : 'h-[125px]')}>
        <Skeleton />
      </div>
    </div>
  );
};
