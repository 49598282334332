import deleteCartItem from 'api/cart/deleteCartItem';
import fetchPickUpCart from 'api/cart/fetchPickUpCart';
import refreshCart from 'api/cart/refreshCart';
import updateCartItem from 'api/cart/updateCartItem';
import addBookingToCart from 'src/api/cart/addBookingToCart';
import addProductToCart from 'src/api/cart/addProductToCart';

export const cartApi = {
  addProductToCart,
  addBookingToCart,
  fetchPickUpCart,
  updateCartItem,
  refreshCart,
  deleteCartItem,
};

export default cartApi;
