import SelectDropdown from './SelectDropdown';
import SelectDrawer from './SelectDrawer';
import { Item } from 'types/ui';
import { classNames } from 'lib/classes';
import React from 'react';

/**
 * renders either a SelectDropdown or SelectDrawer, based on screensize
 */
export const Select = <T extends unknown>({
  options,
  onChange,
  value,
  title,
  className,
  selectFirstValue,
  maxLength,
  setIsOpen,
  name,
  error,
  withReset = true,
  classNameLabel,
  disabledLabel,
  disabledDrawer,
  optionsDivider,
  dataTestId,
}: {
  title: string;
  options: Item<T>[];
  onChange: (value: Item<T> | null) => void;
  onBlur?: (e: FocusEvent) => void;
  value?: Item<T> | null;
  className?: string;
  selectFirstValue?: boolean;
  maxLength?: number;
  setIsOpen?: (open: boolean) => void;
  withReset?: boolean;
  error?: string;
  name?: string;
  classNameLabel?: string;
  disabledLabel?: boolean;
  disabledDrawer?: boolean;
  optionsDivider?: boolean;
  dataTestId?: string;
}) => {
  const inputId = `${name ?? title}-input`;
  let realValue: Item<T> = value
    ? value
    : selectFirstValue
    ? options[0]
    : { label: title, value: undefined as T };
  return (
    <div
      className={`mb-2 mt-4 relative rounded-md shadow-sm`}
      data-testid={dataTestId}
      data-aq={'dropdownList'}
    >
      <div
        className={classNames(
          'pt-0 relative border-2 py-1 shadow-sm',
          error && error.length > 0 ? 'border-red-500' : 'border-gray'
        )}
      >
        {!disabledLabel && (
          <label
            htmlFor={inputId}
            className={`absolute -top-2 left-2 -mt-px inline-block px-1 text-xs font-medium text-gray-900 ${classNameLabel}`}
          >
            {title}
          </label>
        )}

        <div className="hidden lg:flex">
          <SelectDropdown
            value={realValue}
            onChange={onChange}
            options={options}
            className={className || 'w-60'}
            optionsDivider={optionsDivider}
          />
        </div>

        <div className="lg:hidden">
          {disabledDrawer ? (
            <SelectDropdown
              value={realValue}
              onChange={onChange}
              options={options}
              className={className || 'w-60'}
              optionsDivider={optionsDivider}
            />
          ) : (
            <SelectDrawer
              onChange={onChange}
              options={options}
              value={realValue}
              title={title}
              maxLength={maxLength}
              setIsOpen={setIsOpen}
              className={className}
              withReset={withReset}
            />
          )}
        </div>
      </div>
      {error && <p className="text-sm text-red-600 font-normal">{error}</p>}
    </div>
  );
};

export default Select;
