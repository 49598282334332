import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface DividerWithTextProps {
  text?: string;
  color?: 'grey' | 'black';
  spacing?: 'sm' | 'md' | 'lg';
}

const DividerWithText = ({
  text = 'app.ui.or',
  color = 'grey',
  spacing = 'sm',
}: DividerWithTextProps) => {
  const { t } = useTranslation();
  const wrapperClassNames = classNames('flex items-center justify-between', {
    'my-4': spacing === 'sm',
    'my-8': spacing === 'md',
    'my-12': spacing === 'lg',
  });
  const textClassNames = classNames('text-sm flex-shrink mx-2', {
    'text-gray-300': color === 'grey',
  });
  const dividerClassNames = classNames('flex-grow border-t', {
    'border-gray-300': color === 'grey',
    'border-black': color == 'black',
  });

  return (
    <div className={wrapperClassNames}>
      <div className={dividerClassNames}></div>
      <span className={textClassNames}>{t(text)}</span>
      <div className={dividerClassNames}></div>
    </div>
  );
};

export default DividerWithText;
