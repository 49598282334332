import React, { useEffect, useState } from 'react';
import { QuantityPicker } from 'components/quantity/QuantityPicker';
import useCart from 'hooks/useCart';
import { useAppDispatch, useAppSelector } from 'redux/appStore';
import { PRODUCT_MINIMUM_CART_QUANTITY_DEFAULT } from 'constants/product';
import { computeMinimumQuantity } from 'lib/cart/cart';
import { VariantsQuantitySelector } from 'components/quantity/VariantsQuantitySelector';
import SliderMessage from 'components/cartSlideOver/SliderMessage';
import { useTranslation } from 'react-i18next';
import { getIsReservationAvailable } from 'redux/cart/reservation.slice';
import useReservationFlow from 'hooks/reservation/useReservationFlow';
import { QuantityPickerErrorMessage } from 'types/errors';
import { QuantitySelection, VariantsQuantitySelectorChildProps } from 'src/types/quantity';
import { Product, Variant } from 'types/product';

interface Props {
  product: Product;
  variant: Variant | null;
  quantity: number;
  isEnabled?: boolean;
  setQuantity: (q: number) => any;
}

export function Quantity({ product, variant, quantity, setQuantity, isEnabled = true }: Props) {
  const { t } = useTranslation();
  const { getMaxQuantityAllowed } = useCart();
  const [message, setMessage] = useState<QuantityPickerErrorMessage | null>(null);
  const dispatch = useAppDispatch();
  const isReservationAvailable = useAppSelector(getIsReservationAvailable);
  const { updateAvailabilityCalendar } = useReservationFlow();

  useEffect(() => {
    if (!product) return;
    if (quantity < product?.minimumCartQuantity) {
      dispatch(setQuantity(product?.minimumCartQuantity || PRODUCT_MINIMUM_CART_QUANTITY_DEFAULT));
    }
  }, [dispatch, product, quantity, setQuantity]);

  if (!variant) return null;

  const selectedQuantity = computeMinimumQuantity({
    choosenQuantity: quantity,
    product,
  });

  const updatedItemQuantity = async ({ quantity }: QuantitySelection) => {
    setMessage(null);
    dispatch(setQuantity(quantity));
    // update availability calendar if reservation is available
    if (isReservationAvailable) await updateAvailabilityCalendar({ variant, quantity });
  };

  const handleMessage = ({ type, message }: QuantityPickerErrorMessage) => {
    setMessage({ type, message });
  };

  return (
    <div className="w-full mt-3">
      <VariantsQuantitySelector
        isEnabled={isEnabled}
        variantsSelection={[{ variant, quantity: selectedQuantity }]}
        maxBuyingLimit={getMaxQuantityAllowed(product)}
        minBuyingLimit={product?.minimumCartQuantity || PRODUCT_MINIMUM_CART_QUANTITY_DEFAULT}
        onMessage={handleMessage}
        onQuantityChange={updatedItemQuantity}
        quantityPicker={(props: VariantsQuantitySelectorChildProps) => (
          <QuantityPicker {...props} />
        )}
      />
      {message && (
        <SliderMessage type={message.type} title={t('app.ui.quantity_error_header')}>
          <span data-testid={'buyingLimitReached'}>{message.message}</span>
        </SliderMessage>
      )}
    </div>
  );
}
