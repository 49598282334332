import AvailabilityCalendar from 'components/reservation/AvailabilityCalendar';
import AvailabilityTimeslots from 'components/reservation/AvailabilityTimeslots';
import useReservationFlow from 'hooks/reservation/useReservationFlow';
import { useAppDispatch, useAppSelector } from 'redux/appStore';
import {
  getAvailableDates,
  getAvailableTimeslots,
  getPhone,
  getReservationError,
  getSelectedDate,
  selectReservationIsLoading,
  setActiveStartDate,
  setPhone,
  setSelectedDate,
} from 'redux/cart/reservation.slice';
import { DateValuePiece } from 'types/date';
import { formatDateForApi } from 'lib/date';
import { useTranslation } from 'react-i18next';
import PhoneField from 'components/form/PhoneField';
import { E164Number } from 'libphonenumber-js';
import ReservationComments from 'components/reservation/ReservationComments';
import { getAddToCartIsEdit } from 'redux/cart/addToCart.slice';
import { ReservationCalendarSkeleton } from 'components/reservation/components/ReservationCalendarSkeleton';
import { getProductPageProduct } from 'redux/products/productPage.slice';

const ReservationFlow = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const product = useAppSelector(getProductPageProduct);
  const { isReservationAvailable, isLoading } = useReservationFlow();
  const isReservationLoading = useAppSelector(selectReservationIsLoading);
  const isEdit = useAppSelector(getAddToCartIsEdit);
  const selectedDate = useAppSelector(getSelectedDate);
  const availableDates = useAppSelector(getAvailableDates);
  const availableTimeslots = useAppSelector(getAvailableTimeslots);
  const reservationError = useAppSelector(getReservationError);
  const phone = useAppSelector(getPhone);
  const { updateAvailabilityCalendar, updateAvailableTimeslots } = useReservationFlow();

  const onDateSelection = async (selectedDate: DateValuePiece) => {
    const date = formatDateForApi(selectedDate);
    dispatch(setSelectedDate(date));
    await updateAvailableTimeslots({ date });
  };
  const onMonthChange = async (date: DateValuePiece) => {
    const startDate = formatDateForApi(date);
    dispatch(setActiveStartDate(startDate));
    await updateAvailabilityCalendar({ startDate, showFirstAvailability: false });
  };
  const onPhoneChange = (value: E164Number | null) => dispatch(setPhone(value));
  const showTimeslots = !isLoading && Boolean(availableTimeslots && selectedDate);
  const showAdditionalFields = (isEdit || selectedDate) && !product?.isWhiteLabel;

  if (!isReservationAvailable && !isReservationLoading) return null;

  return (
    <>
      <p className="font-bold text-sm mt-5 mb-3">{t('app.ui.add_to_cart.reservation_label')}</p>
      {isReservationLoading ? (
        <ReservationCalendarSkeleton />
      ) : (
        <div className="w-full flex center mt-1 mb-5">
          <AvailabilityCalendar
            selectedDate={selectedDate}
            availableDates={availableDates}
            onSelection={onDateSelection}
            onMonthChange={onMonthChange}
            error={reservationError}
          />
        </div>
      )}
      {showTimeslots && <AvailabilityTimeslots />}
      {showAdditionalFields && (
        <div className={'mb-6'}>
          <PhoneField onChange={onPhoneChange} value={phone} />
          <ReservationComments />
        </div>
      )}
    </>
  );
};

export default ReservationFlow;
