import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Image from 'next/image';
import { ImageType } from 'types/types';
import { useResponsive } from 'hooks/useResponsive';
import { classNames } from 'lib/classes';
import { ImageSliderArrow } from 'components/image/ImageSliderArrow';

interface Props {
  onClick?: (index: number) => void;
  images: ImageType[];
  sizeClassNames: string;
  objectFit?: 'cover' | 'contain';
  bigArrows?: boolean;
  imageAspectClassName?: string;
  // if you want to control the slides with other actions, add this
  current?: number | null;
  // if you want to control the slides with other actions, add this
  setCurrent?: (index: number) => void;
  imageSizes?: string | null;
  className?: string;
  opensLightbox?: boolean | null;
  isSwipable?: boolean;
}

export function ImageSlider({
  images,
  sizeClassNames,
  objectFit,
  bigArrows,
  onClick = () => {},
  imageAspectClassName = 'aspect-w-3 aspect-h-2 w-full',
  setCurrent,
  imageSizes,
  className,
  current = null,
  opensLightbox = false,
  isSwipable = true,
}: Props) {
  const { isLg } = useResponsive();

  return (
    <Carousel
      selectedItem={current || 0}
      onChange={setCurrent}
      swipeable={isSwipable}
      preventMovementUntilSwipeScrollTolerance
      // NB: this repo seems to have some issues. For one, the hover style doesn't work.
      renderArrowNext={(clickHandler: () => void, hasNext: boolean, label: string) => (
        <ImageSliderArrow
          variant="next"
          size={bigArrows ? 'lg' : 'sm'}
          clickHandler={clickHandler}
          hasMore={hasNext}
          label={label}
        />
      )}
      renderArrowPrev={(clickHandler: () => void, hasPrev: boolean, label: string) => (
        <ImageSliderArrow
          variant="prev"
          size={bigArrows ? 'lg' : 'sm'}
          clickHandler={clickHandler}
          hasMore={hasPrev}
          label={label}
        />
      )}
      infiniteLoop
      showThumbs={false}
      showStatus={false}
      showIndicators={images.length > 1}
    >
      {images.map((image, index) => {
        if (
          current === null ||
          index === current ||
          index === current + 1 ||
          index === current - 1
        ) {
          return (
            <div
              key={image.src}
              className={`relative w-full h-full ${sizeClassNames} ${
                opensLightbox ? 'cursor-zoom-in' : ''
              }`}
              onClick={() => onClick(index)}
            >
              {image.src && (
                <div className={imageAspectClassName}>
                  <Image
                    className={classNames(
                      'object-cover object-center w-full h-full',
                      className || ''
                    )}
                    src={image.src}
                    alt={image.alt}
                    fill
                    style={{ objectFit: objectFit || 'cover' }}
                    sizes={imageSizes ? imageSizes : !isLg ? '100vw' : '50vw'}
                    priority={index === 0}
                    quality={isLg ? 100 : 75}
                  />
                </div>
              )}
            </div>
          );
        }

        return <div key={image.src} />;
      })}
    </Carousel>
  );
}
