export function sameOrigin(url: string): boolean {
  const currentUrl = new URL(window.location.href);
  const uri1 = new URL(url);
  if (uri1.host !== currentUrl.host) return false;
  if (uri1.port !== currentUrl.port) return false;
  return uri1.protocol === currentUrl.protocol;
}

export function urlIsOutgoing(href?: string) {
  if (!href || !href.startsWith('http')) {
    return false;
  }

  try {
    const url = new URL(href);
    return !(url.host.endsWith('actievandedag.nl') || url.host.endsWith('actievandedag.be'));
  } catch (e) {
    return false;
  }
}

export function removeBaseUrl(path: string): string {
  try {
    const url = new URL(path);

    return url.pathname;
  } catch (e) {
    return path;
  }
}

export function removeDoubleSlashes(url: string): string {
  return url.replace(/([^:]\/)\/+/g, '$1');
}
