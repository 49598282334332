import { HtmlTransformer } from 'lib/HtmlTransformers/HtmlTransformerChainHandler';
import React, { ReactNode } from 'react';
import { Node } from 'lib/HtmlTransformers/Transform';
import { urlIsOutgoing } from 'lib/url';

export default class ATransformer implements HtmlTransformer {
  transform(node: Node, dataOrChildren: ReactNode, index: number): React.ReactElement {
    node.attribs.rel = urlIsOutgoing(node.attribs.href) ? 'nofollow' : undefined;

    return (
      <a
        key={`a${index}`}
        className="underline cursor-pointer"
        {...node.attribs}
        {...(urlIsOutgoing(node.attribs.href) ? { rel: 'nofollow' } : {})}
      >
        {dataOrChildren}
      </a>
    );
  }

  doesTransform(node: Node): boolean {
    return node.type === 'tag' && node.name === 'a';
  }
}
