import Navbar from './navbar/NavBar';
import { Container } from '@mui/material';
import { MenuHorizontal } from './navbar/MenuHorizontal';
import { PreFooter } from './PreFooter';
import { Taxon } from 'types/types';
import { DefaultHead } from './DefaultHead';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useResponsive } from 'hooks/useResponsive';
import AccountMenu from './menu/AccountMenu';
import { useTranslation } from 'react-i18next';
import CartSlider from './CartSlider';
import Link from 'next/link';
import SeoBlock from './SeoBlock';
import LocationSearchComboBox from 'components/location/search/LocationSearchComboBox';
import { Language, Region } from 'constants/locales';
import config from 'next/config';
import useLocationNavigationLink from 'hooks/location/useLocationNavigationLink';
import { Footer } from 'components/footer/Footer';
import { ReactElement } from 'react';
import { NewsLetterInFooter } from 'components/newsletter/NewsletterInFooter';

interface Props {
  children: any;
  taxons?: Taxon[];
  title?: string;
  hasTitleSuffix?: boolean;
  footerOptions?: {
    sidebar?: boolean;
    showPreFooter?: boolean;
    showNewsletterBanner?: boolean;
    showFooter?: boolean;
    footer?: ReactElement;
  };
  type?: 'default' | 'checkout' | 'login' | 'cart' | 'homepage';
  menu?: 'account';
  seoBlockContent?: string | null;
  enableLocationSearch?: boolean;
  showNavbar?: boolean;
  enableSearch?: boolean;
  showUsps?: boolean;
  enableMobileMenu?: boolean;
}

const defaultFooterOptions = {
  sidebar: false,
  showNewsletterBanner: true,
  showPreFooter: true,
  showFooter: true,
  footer: <Footer />,
};

export function Layout({
  children,
  taxons = [],
  title,
  footerOptions,
  type = 'default',
  hasTitleSuffix = true,
  menu,
  seoBlockContent,
  enableLocationSearch = false,
  showNavbar = true,
  enableSearch = true,
  showUsps = true,
  enableMobileMenu = true,
}: Props) {
  const showSearch = enableSearch && type !== 'checkout' && type !== 'login';
  const { isMobile, isLg } = useResponsive();
  const { t } = useTranslation();
  const { publicRuntimeConfig } = config();
  const { getHomePageHref } = useLocationNavigationLink();

  const isDefault = type === 'default';
  const isHomepage = type === 'homepage';
  const isCheckout = type === 'checkout';
  const isCart = type === 'cart';

  // use default options + overwrite specified values
  footerOptions = {
    ...defaultFooterOptions,
    ...footerOptions,
  };

  return (
    <Container maxWidth={false} disableGutters={true} className="bg-bg-grey">
      <DefaultHead title={title} hasTitleSuffix={hasTitleSuffix} />

      <Navbar
        isHomepage={isHomepage}
        taxons={taxons}
        showAccount={!isCheckout}
        showSearch={showSearch}
        showNavbar={showNavbar}
        enableLocationSearch={enableLocationSearch && !isMobile}
        showUsps={showUsps}
        enableMobileMenu={enableMobileMenu}
      />

      <div suppressHydrationWarning className="min-h-full">
        {isDefault || isHomepage ? (
          <>
            <div className="bg-white shadow">
              {showNavbar && <MenuHorizontal taxons={taxons} renderAsHomePage={isHomepage} />}
              {enableLocationSearch && isMobile && (
                <LocationSearchComboBox
                  region={publicRuntimeConfig.isNL ? Region.NL : Region.BE}
                  language={Language.NL}
                />
              )}
            </div>
          </>
        ) : isCart ? (
          <div className="min-h-full mx-auto md:container md:mx-auto">
            <div className="container px-2 py-4 min-w-max text-purple">
              <Link href={getHomePageHref} prefetch={false}>
                <span className="flex text-sm font-semibold align-top cursor-pointer">
                  <ChevronLeftIcon fontSize={'small'} className="font-semibold" />
                  {t('app.ui.continue_shopping')}
                </span>
              </Link>
            </div>
          </div>
        ) : null}
        {footerOptions.sidebar ? (
          isLg ? (
            <div className="container grid grid-cols-3">
              <div className="col-span-2">{children}</div>
              <PreFooter singleColumn />
            </div>
          ) : (
            <div className="container">{children}</div>
          )
        ) : menu === 'account' && isLg ? (
          <div className="container grid grid-cols-5 bg-bg-grey">
            <div className="col-span-1">
              <AccountMenu />
            </div>
            <div className="col-span-4">{children}</div>
          </div>
        ) : (
          children
        )}
        {footerOptions?.showNewsletterBanner && <NewsLetterInFooter />}
        {footerOptions?.showPreFooter && (!footerOptions.sidebar || !isLg) && <PreFooter />}
        {seoBlockContent && <SeoBlock content={seoBlockContent} />}
      </div>

      {footerOptions.showFooter && footerOptions.footer}

      <CartSlider />
    </Container>
  );
}

export default Layout;
