import { Carousel } from 'react-responsive-carousel';
import { useEffect, useState } from 'react';
import { Product } from 'types/types';
import useResponsive from 'hooks/useResponsive';
import { chunk } from 'lib/products/productSlider';
import { Indicator } from './control/Indicator';
import { TrackingProductList } from 'types/tracking';
import isEqual from 'lodash.isequal';
import { ResponsiveProductCard } from 'components/productCard/ResponsiveProductCard';
import { PRODUCT_CARD_SIZES } from 'constants/productCard/productCard';
import { LoadingProductList } from 'components/productList/LoadingProductList';
import { ProductCardSizes } from 'types/productCard';

interface ProductsSliderCarouselProps {
  products?: Product[];
  carouselContainerStyle?: string;
  chunkSize?: number;
  trackingListName?: TrackingProductList;
  showAddToCartButton?: boolean;
  isLoading?: boolean;
  initialRenderSize?: ProductCardSizes;
}

export function ProductsSliderCarousel({
  products = [],
  carouselContainerStyle = '',
  trackingListName = TrackingProductList.unknown,
  chunkSize = 1,
  showAddToCartButton = false,
  isLoading = false,
  initialRenderSize = PRODUCT_CARD_SIZES.S,
}: ProductsSliderCarouselProps) {
  const { lgBreak, mdBreak } = useResponsive();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [productsToDisplay, setProductsToDisplay] = useState<Product[]>(products);
  const chunks = chunk(productsToDisplay, chunkSize);

  useEffect(() => {
    if (!isEqual(products, productsToDisplay)) {
      setProductsToDisplay(products);
      setCurrentSlide(0);
    }
  }, [products, productsToDisplay]);

  const next = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const prev = () => {
    setCurrentSlide(currentSlide - 1);
  };

  const updateCurrentSlide = (index: number) => {
    setCurrentSlide(index);
  };

  if (isLoading) return <LoadingProductList skeletonAmount={chunkSize} />;
  if (products.length === 0) return null;

  if (chunks.length === 0) {
    return null;
  }

  return (
    <div
      className={`relative h-full my-3 recommendedProducts__carousel ${carouselContainerStyle} withDots`}
    >
      <Carousel
        centerMode
        centerSlidePercentage={75}
        showThumbs={false}
        infiniteLoop={false}
        showStatus={false}
        showIndicators={chunks.length > 1 && products?.length > chunkSize}
        swipeable={true}
        emulateTouch={true}
        selectedItem={currentSlide}
        onChange={updateCurrentSlide}
        renderIndicator={(clickHandler, isSelected, index, label) => (
          <Indicator
            index={index}
            currentSlide={currentSlide}
            onControlClick={clickHandler}
            onPreviousControlClick={prev}
            onNextControlClick={next}
            selected={isSelected}
            label={label}
            totalIndicators={chunks.length}
          />
        )}
      >
        {chunks.map((chunk, position) => (
          <div key={position} className={`grid grid-cols-${chunkSize} gap-x-4 mr-4 my-2`}>
            {chunk.map((product) => (
              <ResponsiveProductCard
                initialRenderSize={initialRenderSize}
                product={product}
                key={product.id}
                imageSlider={false}
                productPosition={position}
                productList={trackingListName}
                isRecommended={true}
                imageSizes={`(max-width: ${mdBreak}px) 90vw, (min-width: ${mdBreak}px) 50vw, (min-width: ${lgBreak}px) 20vw, 30vw`}
                showAddToCartButton={showAddToCartButton}
              />
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
}
