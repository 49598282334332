import { ImageSlider } from 'components/image/ImageSlider';
import { getProductImages } from 'lib/products/product';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCart from 'hooks/useCart';
import BuyingLimitReachedAlertBox from '../product/BuyingLimitReachedAlertBox';
import Select from '../select/Select';
import { Item } from 'types/ui';
import { Variant } from 'types/types';
import useResponsive from 'hooks/useResponsive';
import BigButton from '../BigButton';
import { useAppDispatch, useAppSelector } from 'redux/appStore';
import {
  getAddToCartProduct,
  getAddToCartQuantity,
  getAddToCartSubmitting,
  getAddToCartVariant,
  getQuantityLabel,
  selectTotalOriginalPrice,
  selectTotalPrice,
  setAddToCartVariant,
} from 'redux/cart/addToCart.slice';
import ReservationFlow from 'components/reservation/ReservationFlow';
import {
  getIsReservationAvailable,
  setIsReservationAvailable,
  setSelectedDate,
  setSelectedTimeslot,
} from 'redux/cart/reservation.slice';
import DividerWithText from 'components/common/DividerWithText';
import { getProductPageProduct, setProductPageVariant } from 'redux/products/productPage.slice';
import PriceHorizontally from '../price/PriceHorizontally';
import isReservationEnabled from 'lib/reservation/isReservationEnabled';
import useNotificationToast from 'hooks/notifications/useNotificationToast';
import Abbreviation from 'components/price/Abbreviation';
import { getAbbreviationAmount } from 'lib/abbreviation/abbreviation';
import { AddToCartQuantity } from 'components/cartSlideOver/AddToCartQuantity';

interface AddToCartStepProps {
  onClose: Function;
}

export default function AddToCartStep({ onClose }: AddToCartStepProps) {
  const { t } = useTranslation();
  const { lgBreak, mdBreak } = useResponsive();
  const {
    submitAddToCartContext,
    errorMessage,
    setErrorMessage,
    validateReserveNow,
    canProductBeBought,
    canBeAddedAutomaticallyToTheCart,
  } = useCart();
  const dispatch = useAppDispatch();
  const product = useAppSelector(getAddToCartProduct);
  const productPageProduct = useAppSelector(getProductPageProduct);
  const variant = useAppSelector(getAddToCartVariant);
  const quantity = useAppSelector(getAddToCartQuantity);
  const submitting = useAppSelector(getAddToCartSubmitting);
  const isReservationAvailable = useAppSelector(getIsReservationAvailable);
  const [selectedVariant, setSelectedVariant] = useState<Item<Variant> | null>();
  const { showWarningNotification } = useNotificationToast();

  const selectedTotalPrice = useAppSelector(selectTotalPrice);
  const selectedTotalOriginalPrice = useAppSelector(selectTotalOriginalPrice);
  const selectedQuantityLabel = useAppSelector(getQuantityLabel);

  const isSameProduct = useCallback(
    () => product?.id === productPageProduct?.id,
    [product?.id, productPageProduct?.id]
  );

  const setVariant = (item: Item<Variant> | null) => {
    setSelectedVariant(item);
    const variant = item?.value || null;
    dispatch(setAddToCartVariant(variant));
    dispatch(setIsReservationAvailable(isReservationEnabled(variant)));
    if (isSameProduct()) dispatch(setProductPageVariant(variant));
  };

  useEffect(() => {
    if (!product) return setSelectedVariant(null);

    if (canBeAddedAutomaticallyToTheCart(product))
      void submitAddToCartContext(product, variant, quantity);

    setVariant(
      variant
        ? { label: variant.name, value: variant }
        : { label: product.variants[0].name, value: product.variants[0] }
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (errorMessage) showWarningNotification(t(errorMessage));
  }, [errorMessage, showWarningNotification, t]);

  if (!product || !variant) return null;

  const variantOptions = product.variants.map((variant) => ({
    label: variant.name,
    value: variant,
    disabled: !variant.isSellable,
    disabledTag: t('app.add_to_cart.variant_out_of_stock'),
    disabledRadioIcon: true,
  }));

  const isAddToCartDisabled = submitting || !canProductBeBought;

  const handleAddToCart = async () => {
    if (isReservationAvailable) {
      const error = validateReserveNow();
      if (error) return setErrorMessage(error);
    }
    await submitAddToCartContext(product, variant, quantity);
  };

  const handleReserveLater = async () => {
    dispatch(setSelectedDate(null));
    dispatch(setSelectedTimeslot(null));
    await submitAddToCartContext(product, variant, quantity, true);
  };

  const abbreviationAmount = getAbbreviationAmount(product);

  return (
    <div className="relative mt-1 flex-1 px-4 sm:px-6">
      <div className="h-full border-b-2 pb-2" aria-hidden="true">
        {!canProductBeBought(product) && (
          <div className="my-4">
            <BuyingLimitReachedAlertBox product={product} />
          </div>
        )}

        <a className={`relative overflow-hidden`}>
          <ImageSlider
            images={getProductImages(product, variant ?? product.defaultVariant)}
            sizeClassNames={`w-auto h-[180px] md:w-full md:h-full`}
            imageSizes={`(max-width: ${mdBreak}px) 90vw, (min-width: ${mdBreak}px) 50vw, (min-width: ${lgBreak}px) 20vw, 30vw`}
          />
        </a>

        <div className={'flex justify-center items-end mt-2'}>
          <PriceHorizontally
            price={selectedTotalPrice}
            originalPrice={selectedTotalOriginalPrice}
            showFromText={false}
          />
          {abbreviationAmount && (
            <div>
              <Abbreviation
                quantityLabel={selectedQuantityLabel}
                abbreviationAmount={abbreviationAmount}
              />
            </div>
          )}
        </div>

        {product.variants.length > 1 && (
          <div className={'mt-3'}>
            <Select
              onChange={setVariant}
              title={t('app.ui.chooseAVariant')}
              options={variantOptions}
              value={selectedVariant}
              className="w-full"
              withReset={false}
              classNameLabel="bg-white"
              disabledLabel={true}
              optionsDivider={true}
              disabledDrawer={true}
            />
          </div>
        )}

        <AddToCartQuantity />

        <ReservationFlow />

        <BigButton
          title={t(
            isReservationAvailable ? 'app.ui.reservation.reserve_direct' : 'app.ui.add_to_cart'
          )}
          icon={ShoppingCartIcon}
          className="w-full px-2 flex justify-center font-bold text-base rounded-md py-2 cursor-pointer mt-3 border-2"
          onClick={handleAddToCart}
          disabled={isAddToCartDisabled}
          active={!isAddToCartDisabled}
          loading={submitting}
          data-aq={isReservationAvailable ? 'buyAndReserve' : 'buy'}
        />

        {isReservationAvailable && (
          <>
            <DividerWithText />
            <BigButton
              title={t('app.ui.reservation.reserve_later')}
              className="w-full p-2 flex justify-center font-bold text-base rounded-md my-2 cursor-pointer"
              onClick={handleReserveLater}
              disabled={isAddToCartDisabled}
              active={!isAddToCartDisabled}
              inverted={true}
              loading={submitting}
              data-aq={'buyAndReserveLater'}
            />
          </>
        )}

        <a
          className={'text-orange font-bold flex justify-center mt-3 cursor-pointer'}
          onClick={() => onClose()}
          data-aq={'backButton'}
        >
          {t('app.ui.cart_slider.go_back')}
        </a>
      </div>
    </div>
  );
}
