import { FC, PropsWithChildren } from 'react';
import { isTimeUnitFinished } from 'lib/time/time';
import useCountdown from 'hooks/timer/useCountDown';
import NoSSR from 'components/noSSR/NoSSR';

type Props = PropsWithChildren<{
  endDate: Date;
}>;

export const CountdownDisplay: FC<Props> = ({ endDate }) => {
  const { hours, minutes, seconds } = useCountdown({ endDate });

  const inactiveColor = 'text-[#757575]';
  const activeColor = '';

  const hoursClasses = isTimeUnitFinished(hours, []) ? inactiveColor : activeColor;
  const minutesClasses = isTimeUnitFinished(minutes, [hours]) ? inactiveColor : activeColor;
  const secondsClasses = isTimeUnitFinished(seconds, [hours, minutes])
    ? inactiveColor
    : activeColor;

  return (
    <div className="text-left text-lg pt-2">
      <NoSSR>
        <span className={hoursClasses}>{hours}:</span>
        <span className={minutesClasses}>{minutes}:</span>
        <span className={secondsClasses}>{seconds}</span>
      </NoSSR>
    </div>
  );
};
