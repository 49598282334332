import Image from 'next/image';
import { Image as ImageType } from 'types/types';
import { useResponsive } from 'hooks/useResponsive';

interface Props {
  images: ImageType[];
  imageAlt: string;
  imageSizes?: string;
  priority?: boolean;
}

export const ProductBoxImage = ({ images, imageAlt, priority, imageSizes }: Props) => {
  const { isLg } = useResponsive();
  const imageSrc = images[0].src;
  return (
    <div className="w-full aspect-w-3 aspect-h-2 relative cursor-pointer">
      <Image
        fill
        className={`object-cover object-center h-auto rounded-t-md`}
        src={imageSrc}
        alt={imageAlt}
        priority={priority}
        sizes={imageSizes}
        quality={isLg ? 100 : 75}
      />
    </div>
  );
};
