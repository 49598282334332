import { HtmlTransformer } from 'lib/HtmlTransformers/HtmlTransformerChainHandler';
import React, { ReactNode } from 'react';
import { Node } from 'lib/HtmlTransformers/Transform';

export default class TableTransformer implements HtmlTransformer {
  transform(node: Node, dataOrChildren: ReactNode, index: number): React.ReactElement {
    return (
      <div key={`table${index}`} className={'overflow-auto justify-items-center scrollbar-hide'}>
        <table>{dataOrChildren}</table>
      </div>
    );
  }

  doesTransform(node: Node): boolean {
    return node.type === 'tag' && node.name === 'table';
  }
}
