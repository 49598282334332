const SelectedIcon = ({ selected }: { selected: boolean }) => {
  return (
    <div className="flex justify-end w-10 items-center">
      <div className="w-6 h-6 border-2 rounded-full border-purple p-1">
        {selected ? (
          <span
            className={`flex rounded-full bg-purple h-full w-full ${selected ? 'bg-purple' : null}`}
            aria-hidden="true"
          />
        ) : null}
      </div>
    </div>
  );
};
export default SelectedIcon;
