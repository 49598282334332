import { SwipeableDrawer } from '@mui/material';
import { useState } from 'react';
import DropdownButton from './DropdownButton';
import { Item } from 'types/ui';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import SelectedIcon from './SelectedIcon';
import { useTranslation } from 'react-i18next';

import { classNames } from 'lib/classes';
import DisabledSelectOption from './DisabledSelectOption';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));

const SelectDrawer = <T extends unknown>({
  options,
  value,
  onChange,
  title,
  maxLength,
  setIsOpen,
  className,
  withReset = true,
}: {
  value: Item<T>;
  options: Item<T>[];
  onChange: (value: Item<T> | null) => void;
  title: string;
  maxLength?: number;
  setIsOpen?: (open: boolean) => void;
  className?: string;
  withReset?: boolean;
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  function switchOpen() {
    setOpen(!open);
    setIsOpen?.(!open);
  }

  function reset() {
    onChange(null);
    switchOpen();
  }

  const valueNoOption = !options.find((x) => x.value === value.value);

  return (
    <div className={className} data-aq={'dropdownList'}>
      <DropdownButton onClick={switchOpen} label={value.label} maxLength={maxLength} />
      <SwipeableDrawer
        anchor={'bottom'}
        open={open}
        onClose={switchOpen}
        onOpen={switchOpen}
        sx={{
          borderTopLeftRadius: 10,
          backgroundColor: 'transparent',
          borderTopRightRadius: 10,
        }}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        <StyledBox
          sx={{
            padding: 2,
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
          role="presentation"
        >
          <div className="grid grid-cols-4 mb-10">
            {withReset ? (
              <button
                disabled={valueNoOption}
                className={`text-left ${valueNoOption ? 'disabled:opacity-50' : 'text-purple'}`}
                onClick={reset}
              >
                {t('app.ui.reset')}
              </button>
            ) : (
              <div />
            )}
            <div className="col-span-2 font-bold text-center">{title}</div>
            <button className="text-right text-purple" onClick={switchOpen}>
              {t('app.ui.ready')}
            </button>
          </div>
          <div className="flex flex-col">
            {options.map((option, index) => {
              // @TODO do a shallow diff compare, or add id to Item for example.
              const isSelected = option.label === value.label;

              return option.disabled ? (
                <DisabledSelectOption
                  key={`${option.label}-${index}`}
                  index={index}
                  label={option.label}
                  tag={option.disabledTag}
                />
              ) : (
                <div
                  key={`${option.label}-${index}`}
                  onClick={() => {
                    onChange(option);
                    switchOpen();
                  }}
                  className="flex items-center mb-2"
                  data-aq={'dropdownItem'}
                >
                  <label
                    className={classNames(
                      'flex-1 min-w-0',
                      isSelected ? 'text-purple' : 'text-t-primary'
                    )}
                  >
                    {option.label}
                  </label>
                  <SelectedIcon selected={isSelected} />
                </div>
              );
            })}
          </div>
        </StyledBox>
      </SwipeableDrawer>
    </div>
  );
};

export default SelectDrawer;
