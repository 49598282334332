import ReactHtmlParser from 'react-html-parser';
import React from 'react';
import transform, { Node } from 'lib/HtmlTransformers/Transform';
import useResponsive from 'hooks/useResponsive';

export const HtmlContent = ({ htmlString, strip }: { htmlString: string; strip?: boolean }) => {
  const { isMobile } = useResponsive();
  if (strip) {
    const string = htmlString?.replace(/<[^>]*>?/gm, ''); //strips off html tags
    return (
      <p data-testid="parsedHtmlContentStripped" dangerouslySetInnerHTML={{ __html: string }} />
    );
  }

  return (
    <div data-testid="parsedHtmlContent">
      {ReactHtmlParser(htmlString, {
        transform: (node: Node, index: number) => transform(node, index, isMobile),
      })}
    </div>
  );
};

export default HtmlContent;
