import { capitalizeFirstLetter } from 'lib/strings';
import { productTag } from 'types/product';

export default function ProductLabel({ tag, isBig }: { tag: productTag; isBig?: boolean }) {
  const sizeClass = isBig ? 'text-lg font-bold' : 'text-sm';

  return (
    <span
      className={`inline-flex h-full items-center py-1 rounded-md leading-sm mb-2 px-2.5 ${sizeClass}`}
      style={{ backgroundColor: tag.backgroundColor, color: tag.fontColor }}
      data-aq={'dealLabel'}
    >
      {capitalizeFirstLetter(tag.text, false)}
    </span>
  );
}
