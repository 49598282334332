import { Product } from 'types/types';
import { ExclamationIcon } from '@heroicons/react/solid';
import { getQuantityOfProductInCart } from 'lib/cart/handleAddToCart';
import { useTranslation } from 'react-i18next';
import useStore from 'store';

export default function BuyingLimitReachedAlertBox({ product }: { product: Product }) {
  const { t } = useTranslation();
  const [cart] = useStore('cart');

  return (
    <div className="rounded-md bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <div className="text-sm text-yellow-700">
            <p>
              {t('app.ui.buying_limit_reached', {
                max: product.customerBuyingLimit,
                inCart: getQuantityOfProductInCart(product, cart),
              })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
