import { getCents, getEuros } from 'lib/price';
import CurrencySign from './CurrencySign';
import { PresentationalPriceProps } from 'components/price/OriginalPrice';

export default function CurrentPrice({
  price,
  hasCurrencySign,
  showCents = true,
}: PresentationalPriceProps) {
  const comma = showCents ? ',' : '';

  return (
    <span translate="no" data-aq={'currentPrice'}>
      {hasCurrencySign && (
        <span className="text-3xl md:text-4xl font-extrabold text-purple tracking-tighter pr-2">
          <CurrencySign />
        </span>
      )}
      <span className="text-3xl md:text-4xl font-extrabold text-purple tracking-tighter">
        {getEuros(price)}
        {comma}
      </span>
      {showCents && (
        <span className="text-lg font-extrabold text-purple align-top">{getCents(price)}</span>
      )}
    </span>
  );
}
