import { ProductBoxSkeleton } from 'components/product/ProductBoxSkeleton';
import { classNames } from 'lib/classes';
import useResponsive from 'hooks/useResponsive';

interface LoadingProductListProps {
  skeletonAmount: number;
}

export const LoadingProductList = ({ skeletonAmount }: LoadingProductListProps) => {
  const { isMobile } = useResponsive();

  return (
    <div className="relative flex overflow-auto gap-x-4 gap-y-4 md:grid md:grid-cols-3 scrollbar-hide mb-4">
      {Array(skeletonAmount)
        .fill(1)
        .map((v, index) => (
          <ProductBoxSkeleton
            key={index}
            containerClassname={classNames(isMobile ? 'w-full mx-0 md:mx-2' : '')}
          />
        ))}
    </div>
  );
};
