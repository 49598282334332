import API_ENDPOINTS from 'src/constants/routes/api';
import { doPost, handleResponseErrorIfAny, resolveApiUrl } from 'src/lib/api';
import { transformCartJson } from 'src/lib/transforms';
import { AddBookingToCartPayload, Cart, NotFoundError } from 'src/types/types';

export async function addBookingToCart(payload: AddBookingToCartPayload): Promise<Cart | null> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.BookingCartItems(payload.cartId));
  const res = await doPost(
    endpoint,
    {
      productVariant: '/api/v2/shop/product-variants/' + payload.item.productVariantCode,
      externalBookingId: payload.item.externalBookingId,
    },
    {
      headers: payload.authToken ? { 'X-AUTH-TOKEN': payload.authToken } : {},
    }
  );

  try {
    const result = await handleResponseErrorIfAny(res);
    const cart = transformCartJson(result);

    return {
      ...cart,
      isLoading: false,
    };
  } catch (e) {
    if (e instanceof NotFoundError) return null;

    throw e;
  }
}

export default addBookingToCart;
