import { Item } from 'types/ui';
import DisabledSelectOption from './DisabledSelectOption';
import { classNames } from 'lib/classes';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import SelectedIcon from './SelectedIcon';

export default function SelectDropdownOption<T extends unknown>({
  option,
  selected,
  active,
  isHeader,
  isOpen,
  className,
  onSelect,
}: {
  option: Item<T>;
  selected?: boolean;
  active?: boolean;
  isHeader?: boolean;
  isOpen?: boolean;
  className?: string;
  onSelect?: (item: Item<T> | T) => void;
}) {
  const Icon = option?.icon;

  return (
    <div
      className={classNames('flex justify-between items-center', className || '')}
      onClick={onSelect ? () => onSelect(option) : undefined}
    >
      {option?.disabled ? (
        <div className="flex-1 pl-3 max-w-full">
          <DisabledSelectOption index={1} label={option?.label || ''} tag={option?.disabledTag} />
        </div>
      ) : (
        <>
          <div className="flex items-center">
            <span
              className={classNames(
                selected || active ? 'text-purple' : 'font-normal',
                'block px-2'
              )}
            >
              {option?.label}
            </span>
            {Icon && (
              <Icon className={selected || active ? 'font-semibold text-purple' : 'font-normal'} />
            )}
          </div>

          {isHeader ? (
            <div className="">{isOpen ? <AiFillCaretUp /> : <AiFillCaretDown />}</div>
          ) : (
            !option?.disabledRadioIcon && <SelectedIcon selected={selected!} />
          )}
        </>
      )}
    </div>
  );
}
