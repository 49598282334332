import Image from 'next/image';
import { useTranslation } from 'react-i18next';

const DAGPAKKER_SRC_LARGE = '/img/dagpakker-overlay-large.gif';

export const DagpakkerStickerLarge = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full h-full">
      <Image
        width={400}
        height={100}
        className="object-cover"
        src={DAGPAKKER_SRC_LARGE}
        alt={t('app.catch_of_the_day.extra_discount_untill_end_date')}
      />
    </div>
  );
};
