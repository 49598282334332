import { HtmlTransformer } from 'lib/HtmlTransformers/HtmlTransformerChainHandler';
import React, { ReactNode } from 'react';
import { Node } from 'lib/HtmlTransformers/Transform';

export default class PTransformer implements HtmlTransformer {
  transform(node: Node, dataOrChildren: ReactNode, index: number): React.ReactElement {
    return (
      <p key={`p${index}`} className={'py-2'}>
        {dataOrChildren}
      </p>
    );
  }

  doesTransform(node: Node): boolean {
    return node.type === 'tag' && node.name === 'p';
  }
}
