import { MinusIcon, PlusIcon } from '@heroicons/react/solid';
import useCounter from 'hooks/quantity/useCounter';

import classNames from 'classnames';
import { VariantsQuantitySelectorChildProps } from 'types/quantity';

export function QuantityPicker({
  label,
  max,
  min = 1,
  quantity,
  onChange,
  onError,
  isEnabled = true,
}: VariantsQuantitySelectorChildProps) {
  const { canIncrease, canDecrease, increaseQuantity, decreaseQuantity } = useCounter({
    max,
    min,
    quantity,
    onChange,
    isEnabled,
    onError,
  });

  return (
    <>
      <div
        data-testid="quantityPicker"
        className="flex w-full pl-4 text-left bg-white border-2 border-gray rounded-sm shadow-sm cursor-default focus:outline-none focus:ring-1 sm:text-sm"
      >
        <div
          translate="no"
          data-testid="quantityLabel"
          className={classNames('flex-1 py-2 items-center text-left select-none', {
            'text-gray-400': !isEnabled,
          })}
        >
          {quantity} {label}
        </div>
        <div
          className={classNames(
            'inline-flex items-center px-2 text-lg text-gray-900 bg-white border-l-2 select-none',
            {
              'cursor-pointer': isEnabled,
              'cursor-not-allowed': !isEnabled,
            }
          )}
          onClick={() => decreaseQuantity()}
        >
          <MinusIcon
            className={`w-4 h-4 ${canDecrease ? 'text-black' : 'text-gray-400'}`}
            aria-hidden="true"
            data-testid="quantityPickerMinusIcon"
          />
        </div>
        <div
          className={classNames(
            'inline-flex items-center px-2 text-lg text-gray-900 bg-white border-l-2 select-none',
            {
              'cursor-pointer': isEnabled,
              'cursor-not-allowed': !isEnabled,
            }
          )}
          onClick={() => increaseQuantity()}
        >
          <PlusIcon
            className={`w-4 h-4 ${canIncrease ? 'text-black' : 'text-gray-400'}`}
            aria-hidden="true"
            data-testid="quantityPickerPlusIcon"
          />
        </div>
      </div>
    </>
  );
}
