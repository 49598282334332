import Image from 'next/image';
import { useTranslation } from 'react-i18next';

const DAGPAKKER_SRC_SMALL = '/img/dagpakker-overlay-small.gif';

export const DagpakkerStickerSmall = () => {
  const { t } = useTranslation();

  return (
    <span
      className={`inline-flex py-1 mb-2 pl-2.5 pr-2 rounded-md text-sm text-white bg-purple-light whitespace-nowrap`}
    >
      {t('app.catch_of_the_day.only_today')}
      <span className="h-5 w-6">
        <Image
          height={20}
          width={20}
          src={DAGPAKKER_SRC_SMALL}
          className="h-5 w-6 object-contain ml-0.5"
          alt={t('app.catch_of_the_day.extra_discount_untill_end_date')}
        />
      </span>
    </span>
  );
};
