import { Product } from 'types/types';
import { useResponsive } from 'hooks/useResponsive';
import { TrackingProductList } from 'types/tracking';
import { ResponsiveProductCard } from 'components/productCard/ResponsiveProductCard';
import { PRODUCT_CARD_SIZES } from 'constants/productCard/productCard';
import { LoadingProductList } from 'components/productList/LoadingProductList';
import { ProductCardSizes } from 'types/productCard';

interface ProductsRows {
  products?: Product[];
  trackingListName?: TrackingProductList;
  showAddToCartButton?: boolean;
  isLoading?: boolean;
  loadingSkeletonAmount?: number;
  initialRenderSize?: ProductCardSizes;
}

export function ProductsRows({
  products = [],
  trackingListName = TrackingProductList.unknown,
  showAddToCartButton = false,
  isLoading = false,
  loadingSkeletonAmount = 1,
  initialRenderSize = PRODUCT_CARD_SIZES.S,
}: ProductsRows) {
  const { lgBreak } = useResponsive();

  if (isLoading) return <LoadingProductList skeletonAmount={loadingSkeletonAmount} />;

  if (!products.length) {
    return null;
  }

  return (
    <div
      data-testid="productListMobileVertical"
      className="relative flex overflow-auto gap-x-4 gap-y-4 md:grid md:grid-cols-3 md:gap-y-0 scrollbar-hide"
    >
      {products.map((product, index) => (
        <div className="mb-4 snap-center" key={product.id && product.id.toString() + index}>
          <ResponsiveProductCard
            initialRenderSize={initialRenderSize}
            product={product}
            className={'w-60 lg:w-full'}
            productList={trackingListName}
            productPosition={index + 1}
            imageSizes={`(min-width: ${lgBreak}px) 25vw, 40vw`}
            showAddToCartButton={showAddToCartButton}
          />
        </div>
      ))}
    </div>
  );
}
