import HtmlContent from './HtmlContent';

export default function SeoBlock({ content }: { content: string }) {
  return (
    <div className="container my-4 mb-6">
      <div data-testid="seoBlockContent" className="break-words mx-2 md:mx-0">
        <HtmlContent htmlString={content} />
      </div>
    </div>
  );
}
