import React from 'react';
import { Node } from 'lib/HtmlTransformers/Transform';
import { NodeSanitizers } from 'lib/HtmlTransformers/NodeSanitizerChainHandler';

export default class InlineStyleSanitizer implements NodeSanitizers {
  sanitize(node: Node): Node {
    node.attribs.style = this.inlineStyleToObject(node.attribs?.style);

    return node;
  }

  needsSanitizing(node: Node): boolean {
    // if node.attribs?.style is a string, then return true
    return node.type === 'tag' && typeof node.attribs?.style === 'string';
  }

  private inlineStyleToObject(inlineStyle = '') {
    // just return empty object if the inlineStyle is empty
    if (inlineStyle === '') {
      return {};
    }

    return inlineStyle.split(';').reduce((styleObject: any, stylePropertyValue) => {
      // extract the style property name and value
      let [property, value] = stylePropertyValue
        .split(/^([^:]+):/)
        .filter((val, i) => i > 0)
        .map((item) => {
          const trimmedItem = item.trim();
          return trimmedItem.startsWith('url') ? trimmedItem : trimmedItem.toLowerCase();
        });

      // if there is no value (i.e. no : in the style) then ignore it
      if (value === undefined) {
        return styleObject;
      }

      // convert the property name into the correct React format
      // remove all hyphens and convert the letter immediately after each hyphen to upper case
      // additionally don't uppercase any -ms- prefix
      // e.g. -ms-style-property = msStyleProperty
      //      -webkit-style-property = WebkitStyleProperty

      property = property
        .replace(/^-ms-/, 'ms-')
        .replace(/-(.)/g, (_, character) => character.toUpperCase());

      // add the new style property and value to the style object
      styleObject[property] = value;

      return styleObject;
    }, {});
  }
}
