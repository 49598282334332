import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Calendar, { TileDisabledFunc } from 'react-calendar';
import { region } from 'lib/channel/locales';
import { isSameDay } from 'date-fns';
import { OnArgs, TileArgs } from 'react-calendar/src/shared/types';
import {
  FaRegArrowAltCircleLeft as PrevIcon,
  FaRegArrowAltCircleRight as NextIcon,
} from 'react-icons/fa';
import { CalendarValue, DateValuePiece } from 'types/date';
import { useTranslation } from 'react-i18next';
import { setActiveStartDate as _setActiveStartDate } from 'redux/cart/reservation.slice';
import { useAppDispatch } from 'redux/appStore';

interface AvailabilityCalendarProps {
  selectedDate: string | null;
  availableDates?: string[];
  onSelection?: (date: DateValuePiece) => void;
  onMonthChange?: (startDate: DateValuePiece) => void;
  error: boolean;
}

const AvailabilityCalendar = ({
  selectedDate,
  availableDates = [],
  onSelection,
  onMonthChange,
  error,
}: AvailabilityCalendarProps) => {
  const { t } = useTranslation();
  const date = selectedDate ? new Date(selectedDate) : null;
  const [activeStartDate, setActiveStartDate] = useState<Date>(new Date());
  const dispatch = useAppDispatch();

  const minDate = useMemo(() => new Date(), []); // min date is today

  useEffect(() => {
    if (availableDates.length) {
      const startDate = new Date(availableDates[0]);
      setActiveStartDate(startDate);
      dispatch(_setActiveStartDate(availableDates[0]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableDates]);

  const handleSelection = (date: CalendarValue) => {
    onSelection?.(date as DateValuePiece);
  };

  const tileDisabled = useCallback(
    ({ date, view }: TileArgs): boolean => {
      // Disable tiles in month view only
      if (view === 'month') {
        if (!availableDates?.length) return true;
        // Check if a date React-Calendar wants to check is on the list of disabled dates
        return availableDates.findIndex((dDate) => isSameDay(new Date(dDate), date)) < 0;
      }
      return false;
    },
    [availableDates]
  ) as unknown as TileDisabledFunc;

  const handleMonthChange = useCallback(
    ({ action, activeStartDate, view }: OnArgs) => {
      if (view === 'month' && (action === 'next' || action === 'prev')) {
        if (activeStartDate) setActiveStartDate(activeStartDate);
        onMonthChange?.(activeStartDate); // emit the value
      }
    },
    [onMonthChange]
  );

  return (
    <div className="relative">
      {error && (
        <div className="reservation-error absolute bottom-zero w-full z-45 bg-white opacity-75 flex center">
          <p className="text-error">{t('app.ui.add_to_cart.not_available')}</p>
        </div>
      )}
      <Calendar
        locale={region}
        onChange={handleSelection}
        onActiveStartDateChange={handleMonthChange}
        value={date}
        next2Label={null}
        prev2Label={null}
        nextLabel={<NextIcon className="text-purple w-5 h-5" />}
        prevLabel={<PrevIcon className="text-purple w-5 h-5" />}
        minDetail="month"
        maxDetail="month"
        showNeighboringMonth={false}
        tileDisabled={tileDisabled}
        activeStartDate={activeStartDate}
        minDate={minDate}
      />
    </div>
  );
};

export default AvailabilityCalendar;
