import { useEffect, useState } from 'react';
import { QuantityPickerError, QuantityPickerErrorCauses } from 'constants/product';

export type onChangeQuantityFunction = (
  newQuantity: number
) => Promise<number | void> | number | void;

type UseCounterProps = {
  max: number;
  min?: number;
  quantity: number;
  onChange: onChangeQuantityFunction | undefined;
  isEnabled?: boolean;
  onError?: (errorCause: QuantityPickerError) => void;
};

export default function useCounter({
  max,
  min = 1,
  quantity,
  onChange,
  isEnabled = true,
  onError,
}: UseCounterProps): {
  canIncrease: boolean;
  canDecrease: boolean;
  increaseQuantity: () => void;
  decreaseQuantity: () => void;
} {
  const [canIncrease, setCanIncrease] = useState(isEnabled);
  const [canDecrease, setCanDecrease] = useState(isEnabled);

  useEffect(() => {
    setCanIncrease(isEnabled && quantity < max);
    setCanDecrease(isEnabled && quantity > min);
  }, [isEnabled, max, min, quantity]);

  const handleOnChange = async function (newQuantity: number) {
    await onChange?.(newQuantity);
  };

  const increaseQuantity = async () => {
    if (!canIncrease) return handleError(QuantityPickerErrorCauses.MAX_REACHED);

    await handleOnChange(quantity + 1);
  };

  const decreaseQuantity = async () => {
    if (!canDecrease) {
      const reason =
        quantity === 0
          ? QuantityPickerErrorCauses.ZERO_REACHED
          : QuantityPickerErrorCauses.MIN_REACHED;

      return handleError(reason);
    }

    await handleOnChange(quantity - 1);
  };

  const handleError = (errorCause: QuantityPickerError): void => {
    if (!onError) return;
    if (!isEnabled) return onError(QuantityPickerErrorCauses.DISABLED);
    return onError(errorCause);
  };

  return {
    canIncrease,
    canDecrease,
    increaseQuantity,
    decreaseQuantity,
  };
}
