import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import ActivityIndicator from './ActivityIndicator';

type BigButtonProps = {
  title: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  onClick: (e: React.SyntheticEvent) => void;
  onClickDisabled?: () => void;
  loading?: boolean;
  disabled?: boolean;
  active?: boolean;
  inverted?: boolean;
  className?: string | undefined;
  dataTestId?: string | undefined;
};

const BigButton = ({
  title,
  icon: Icon,
  onClick,
  loading,
  disabled = false,
  active = true,
  inverted = false,
  onClickDisabled,
  className = undefined,
  dataTestId = undefined,
  ...rest
}: BigButtonProps) => {
  const colorClass = inverted
    ? `bg-white border ${active ? 'text-purple border-purple' : 'text-disabled border-disabled'}`
    : `text-white ${active ? 'bg-orange' : 'bg-disabled'}`;
  const classes =
    className ??
    'w-full px-2 flex justify-center font-bold text-base rounded-md py-2 cursor-pointer';

  return (
    <button
      disabled={onClickDisabled ? undefined : disabled}
      className={`${colorClass} ${classes}`}
      onClick={disabled ? onClickDisabled : onClick}
      data-testid={dataTestId}
      {...rest}
    >
      {title}
      {loading ? (
        <ActivityIndicator className="ml-2" color={inverted ? 'black' : 'white'} />
      ) : Icon ? (
        <Icon className="ml-2" />
      ) : null}
    </button>
  );
};

export default BigButton;
