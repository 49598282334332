import { HtmlTransformer } from 'lib/HtmlTransformers/HtmlTransformerChainHandler';
import React, { ReactNode } from 'react';
import { Node } from 'lib/HtmlTransformers/Transform';

export default class H3Transformer implements HtmlTransformer {
  transform(node: Node, dataOrChildren: ReactNode, index: number): React.ReactElement {
    return (
      <h3 key={`h3${index}`} className="py-4 text-lg font-semibold">
        {dataOrChildren}
      </h3>
    );
  }

  doesTransform(node: Node): boolean {
    return node.type === 'tag' && node.name === 'h3';
  }
}
