import * as React from 'react';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { preAddToCartClick } from 'modules/tracking/events/events';
import { Product } from 'types/types';
import { TrackingProductList } from 'types/tracking';
import useCart from 'hooks/useCart';

export default function AddToCart({
  product,
  productList,
  productPosition,
  isRecommended = false,
}: {
  product: Product;
  productList?: TrackingProductList;
  productPosition: number;
  isRecommended?: boolean;
}) {
  const { openSlideOver } = useCart();

  const _openSlideOver = async (e: React.SyntheticEvent<HTMLDivElement> | undefined) => {
    e?.stopPropagation();
    e?.preventDefault();

    await openSlideOver({
      product,
      productList,
      isRecommended,
      quantity: product.minimumCartQuantity,
    });

    await preAddToCartClick({ product, productList, productPosition });
  };

  return (
    <div key={'variant-selector'}>
      <div
        onClick={_openSlideOver}
        className="flex items-center justify-center p-2 border rounded-full cursor-pointer border-orange hover:bg-gray-200"
        data-testid={'addToCartButton'}
      >
        <AddShoppingCartIcon className="text-orange" />
      </div>
    </div>
  );
}
