import { useCallback } from 'react';
import {
  CART_REMINDER_MAX_VIEW_COUNT,
  CART_REMINDER_STORAGE_KEY_COUNTER,
  CART_REMINDER_STORAGE_KEY_LAST_SET,
  CART_REMINDER_STORAGE_NAME,
} from 'constants/common';

const getCartReminderCount = (): number => {
  if (typeof window === 'undefined') return 0;

  const counterItem = window.localStorage.getItem(CART_REMINDER_STORAGE_NAME);

  if (!counterItem) return 0;

  return JSON.parse(counterItem)?.[CART_REMINDER_STORAGE_KEY_COUNTER] || 0;
};

export default function useCartReminderViewCount(): {
  addView: () => void;
  resetCounter: () => void;
  hasReachedMaxViewCount: () => boolean;
} {
  const addView = useCallback(() => {
    if (typeof window === 'undefined') return;

    const newCounter = getCartReminderCount() + 1;

    window.localStorage.setItem(
      CART_REMINDER_STORAGE_NAME,
      JSON.stringify({
        [CART_REMINDER_STORAGE_KEY_COUNTER]: newCounter,
        [CART_REMINDER_STORAGE_KEY_LAST_SET]: new Date().toISOString(),
      })
    );
  }, []);

  const resetCounter = useCallback(() => {
    if (typeof window === 'undefined') return;

    window.localStorage.removeItem(CART_REMINDER_STORAGE_NAME);
  }, []);

  const hasReachedMaxViewCount = useCallback(() => {
    return getCartReminderCount() >= CART_REMINDER_MAX_VIEW_COUNT;
  }, []);

  return { addView, resetCounter, hasReachedMaxViewCount };
}
