import { HtmlTransformer } from 'lib/HtmlTransformers/HtmlTransformerChainHandler';
import React, { ReactNode } from 'react';
import { Node } from 'lib/HtmlTransformers/Transform';

export default class StrongTransformer implements HtmlTransformer {
  transform(node: Node, dataOrChildren: ReactNode, index: number): React.ReactElement {
    return (
      <span key={`strong${index}`} className="font-semibold">
        {dataOrChildren}
      </span>
    );
  }

  doesTransform(node: Node): boolean {
    return node.type === 'tag' && node.name === 'strong';
  }
}
