import { HtmlTransformer } from 'lib/HtmlTransformers/HtmlTransformerChainHandler';
import React, { ReactNode } from 'react';
import { Node } from 'lib/HtmlTransformers/Transform';

export default class ImgTransformer implements HtmlTransformer {
  transform(node: Node, dataOrChildren: ReactNode, index: number): React.ReactElement {
    return (
      <img
        key={`img${index}`}
        alt={node.attribs.alt}
        src={node.attribs.src}
        width={node.attribs.style?.width || '250px'}
        height={node.attribs.style?.height || '250px'}
      />
    );
  }

  doesTransform(node: Node): boolean {
    return node.type === 'tag' && node.name === 'img';
  }
}
