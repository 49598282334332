import * as React from 'react';
import { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import useStore from 'store';
import productApi from 'api/product';
import { Product } from 'types/types';
import useResponsive from 'hooks/useResponsive';
import SectionTitle from '../SectionTitle';
import { TrackingProductList } from 'types/tracking';
import { useAppSelector } from 'redux/appStore';
import { getAddToCartProduct } from 'redux/cart/addToCart.slice';
import { RecommendedProductLocation } from 'constants/product';
import { ProductsSliderCarousel } from 'components/productList/Carousel/ProductsSliderCarousel';
import { ProductsRows } from 'components/productList/Row/ProductsRows';

interface RecommendedProductsSliderProps {
  title: string;
  location: RecommendedProductLocation;
  carouselContainerStyle?: string;
  chunkSize?: number;
}

export function RecommendedProductsSlider({
  title,
  location,
  carouselContainerStyle = '',
  chunkSize = 1,
}: RecommendedProductsSliderProps) {
  const { isLg } = useResponsive();
  const [products, setProducts] = useState<Product[]>([]);
  const [cart] = useStore('cart');
  const product = useAppSelector(getAddToCartProduct);

  useEffect(() => {
    if (!cart?.tokenValue) return;

    productApi.fetchRecommendedProducts(location, cart.tokenValue).then((products) => {
      setProducts(products);
    });
  }, [cart, product, chunkSize, location]);

  if (!products.length) return null;

  return (
    <>
      <SectionTitle title={title} />
      {isLg ? (
        <ProductsSliderCarousel
          products={products}
          carouselContainerStyle={carouselContainerStyle}
          chunkSize={chunkSize}
          trackingListName={TrackingProductList.recommendedProducts}
          showAddToCartButton={true}
        />
      ) : (
        <ProductsRows
          products={products}
          loadingSkeletonAmount={chunkSize}
          trackingListName={TrackingProductList.recommendedProducts}
          showAddToCartButton={true}
        />
      )}
    </>
  );
}
