import { HtmlTransformer } from 'lib/HtmlTransformers/HtmlTransformerChainHandler';
import React, { ReactNode } from 'react';
import { Node } from 'lib/HtmlTransformers/Transform';

export default class EmptyStringNodeTransformer implements HtmlTransformer {
  transform(node: Node, dataOrChildren: ReactNode, index: number): null {
    return null;
  }

  doesTransform(node: Node, dataOrChildren: ReactNode | string): boolean {
    return typeof dataOrChildren === 'string' && dataOrChildren.trimEnd() === '';
  }
}
