import { resolveClosestAddressToUser } from 'lib/address/resolveClosestAddressToUser';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'redux/appStore';
import { selectGeoFilter } from 'redux/geolocation/geolocation.slice';
import { ExtendedProduct, Product } from 'types/types';
import { FaMapPin } from 'react-icons/fa6';

interface ProductLocationPinProps {
  product: Product | ExtendedProduct;
  mapAnchor?: string | null;
  showIcon?: boolean;
}

export default function ProductLocationPin({
  product,
  mapAnchor,
  showIcon = false,
}: ProductLocationPinProps) {
  const { t } = useTranslation();
  const geoFilter = useAppSelector(selectGeoFilter);

  if (!product?.addresses?.length) return null;

  const closestAddress = resolveClosestAddressToUser(product.addresses, geoFilter.query);

  const text = `${closestAddress.city}${
    product.addresses.length > 1
      ? ' ' + t('app.ui.product.and_other_cities', { count: product.addresses.length - 1 })
      : ''
  }`;

  return (
    <div className="text-xs flex gap-x-1 mb-1 text-gray-500" data-aq={'location'}>
      {showIcon && <FaMapPin className="h-3" title={product.name} />}
      {!!mapAnchor ? (
        <a className="line-clamp-1" href={`#${mapAnchor}`}>
          {text}
        </a>
      ) : (
        <span className="line-clamp-1">{text}</span>
      )}
    </div>
  );
}
