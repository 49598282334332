import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { classNames } from 'lib/classes';
import { ReactElement } from 'react';

const SectionTitle = ({
  title,
  href,
  showLink = false,
  linkContent,
}: {
  title: string;
  href?: string;
  showLink?: boolean;
  linkContent?: string | ReactElement;
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-between mt-6 mb-4 items-start">
      <h2 className="text-lg font-extrabold">{title}</h2>
      {showLink && href && (
        <Link
          href={href}
          className={classNames(
            'md:flex underline text-purple hover:text-purple whitespace-nowrap pt-0.5',
            showLink ? 'flex' : 'hidden'
          )}
        >
          {linkContent || t('app.ui.section.showall')}
        </Link>
      )}
    </div>
  );
};

export default SectionTitle;
