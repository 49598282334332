import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useState } from 'react';
import { E164Number } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';
import { parsePhoneValue } from 'lib/phone';
import validation from 'lib/validation';
import { region } from 'lib/channel/locales';

interface PhoneFieldProps {
  value: string | null;
  onChange?: (value: E164Number | null) => void;
}

function PhoneField({ value, onChange }: PhoneFieldProps) {
  const { t } = useTranslation();
  const parsedValue = parsePhoneValue(value);
  const [val, setVal] = useState<E164Number | null>(parsedValue?.number || null);
  const [hasErrors, setHasErrors] = useState(false);

  const handleOnChange = (value: E164Number) => {
    setVal(value || null);
    handleValidate(value);
    if (onChange) onChange(value);
  };

  const handleValidate = (value: E164Number) => {
    if (!validation.isValidPhone(value)) {
      return setHasErrors(true);
    }

    setHasErrors(false);
  };

  return (
    <div>
      <PhoneInput
        placeholder={t('app.ui.phone.placeholder')}
        value={val || ''}
        onChange={handleOnChange}
        defaultCountry={region}
        className="flex w-full text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm"
        international
        countryCallingCodeEditable={false}
        countrySelectProps={{ unicodeFlags: true }}
        countryOptionsOrder={['NL', 'BE', 'DE', '|', '...']}
      />
      {hasErrors && (
        <p className={'text-red-500 text-xs italic mt-1'}>{t('app.ui.invalid_phone')}</p>
      )}
    </div>
  );
}

export default PhoneField;
