import { classNames } from 'lib/classes';

export function Control(props: {
  disabled: boolean;
  children: React.ReactNode;
  onClick: () => void;
  testId: string;
}) {
  return (
    <span
      data-testid={props.testId}
      className={classNames(
        'relative mx-4 inline-block cursor-pointer align-top select-none',
        props.disabled ? 'opacity-50' : ''
      )}
      onClick={props.disabled ? () => {} : props.onClick}
    >
      {props.children}
    </span>
  );
}
