import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from 'lib/strings';
import { determineMaxQuantity, determineMinQuantity } from 'lib/quantity/Quantity';
import useQuantityErrorHandling, { OnMessageHandler } from 'hooks/quantity/useQuantityErrorHandler';
import {
  onQuantityChangeHandler,
  QuantitySelection,
  VariantsQuantitySelectorChildProps,
} from 'src/types/quantity';

interface Props {
  quantityPicker: (props: VariantsQuantitySelectorChildProps) => ReactNode;
  variantsSelection: QuantitySelection[];
  maxBuyingLimit?: number;
  minBuyingLimit: number;
  onQuantityChange: onQuantityChangeHandler;
  onMessage?: OnMessageHandler;
  isLoading?: boolean;
  isEnabled?: boolean;
}

export function VariantsQuantitySelector({
  quantityPicker,
  maxBuyingLimit,
  minBuyingLimit,
  variantsSelection,
  onQuantityChange,
  onMessage,
  isLoading = false,
  isEnabled = true,
}: Props) {
  const { t } = useTranslation();

  const { handleError } = useQuantityErrorHandling({
    onMessage: isEnabled ? onMessage : undefined,
    minBuyingLimit,
    isLoading,
  });

  const quantityTotal = useMemo(() => {
    return variantsSelection.reduce((acc, item) => {
      return acc + item.quantity;
    }, 0);
  }, [variantsSelection]);

  return (
    <>
      {variantsSelection.map(({ variant, quantity }: QuantitySelection, index: number) => {
        const label =
          variantsSelection.length > 1
            ? capitalizeFirstLetter(variant.name)
            : t(`app.ui.quantity.${variant.quantityLabel?.toLowerCase()}`, { count: quantity });

        return (
          <div key={`quantityWrapper${index}`} className="my-1">
            {quantityPicker({
              label,
              isEnabled: isEnabled && !isLoading && variant.isSellable,
              quantity,
              min: determineMinQuantity(quantityTotal, quantity, minBuyingLimit),
              max: determineMaxQuantity(quantityTotal, quantity, maxBuyingLimit),
              onChange: async (newQuantity) => {
                await onQuantityChange({ variant: variant, quantity: newQuantity });
              },
              onError: (errorCause) => {
                handleError({ errorCause, variant });
              },
            })}
          </div>
        );
      })}
    </>
  );
}
