import { Cart } from 'types/types';
import { doFetch, getPublicRuntimeConfig, resolveApiUrl } from 'lib/api';
import { CONTENT_TYPES } from 'constants/enums';
import { transformCartJson } from 'lib/transforms';
import API_ENDPOINTS from 'constants/routes/api';

export async function fetchPickUpCart(authToken: string | null): Promise<Cart> {
  const config = getPublicRuntimeConfig();
  const endpoint = resolveApiUrl(API_ENDPOINTS.GetPickUpCart);

  const headers = {
    accept: CONTENT_TYPES.APPLICATION_JSON,
    'Content-Type': CONTENT_TYPES.APPLICATION_JSON,
  };

  if (authToken !== null) {
    Object.assign(headers, {
      'X-AUTH-TOKEN': authToken,
    });
  }

  const res = await doFetch(endpoint, {
    body: JSON.stringify({ localeCode: config.locale }),
    headers: headers,
    method: 'POST',
    redirect: 'follow',
    mode: 'cors',
  });

  if (!res.ok) {
    throw new Error();
  }

  const result = await res.json();

  return transformCartJson(result);
}

export default fetchPickUpCart;
