import { BackendError, Cart } from 'types/types';
import { doFetch, resolveApiUrl } from 'lib/api';
import { transformCartJson } from 'lib/transforms';
import { captureTypeErrorIfInvalid } from 'lib/error';
import API_ENDPOINTS from 'constants/routes/api';

export default async function deleteCartItem({
  cartToken,
  cartItemId,
  authToken,
}: {
  cartToken: string;
  cartItemId: number;
  authToken: string | null;
}): Promise<Cart | null> {
  const headers = {
    accept: 'application/json',
    'Content-Type': 'application/merge-patch+json',
  };

  if (authToken !== null) {
    Object.assign(headers, { 'X-AUTH-TOKEN': authToken });
  }

  const endpoint = resolveApiUrl(API_ENDPOINTS.DeleteCartItem(cartToken, cartItemId));
  const res = await doFetch(endpoint, {
    headers: headers,
    method: 'DELETE',
    redirect: 'follow',
    mode: 'cors',
  });

  if (!res.ok) {
    if (res.status === 404) {
      return null;
    }
    if (res.status === 400) {
      const result = await res.json();
      throw new BackendError(result.violations);
    }
    throw new Error();
  }

  const result = await res.json();
  const cart = transformCartJson(result);

  captureTypeErrorIfInvalid('Cart', cart);
  return cart;
}
