import { useTranslation } from 'react-i18next';
import OriginalPrice from './OriginalPrice';
import CurrentPrice from './CurrentPrice';
import Abbreviation, { AbbreviationProps } from 'components/price/Abbreviation';
import classNames from 'classnames';

export const SHOW_CENTS_TILL_AMOUNT = 100000; // EUR 1000,00

export interface PresentationalPriceProps {
  price: number;
  originalPrice?: null | number;
  showFromText?: boolean;
  hasStar?: boolean;
  hasCurrencySign?: boolean;
}

type PriceWithUnitAbbreviationProps = PresentationalPriceProps & AbbreviationProps;

export default function PriceWithUnitAbbreviation({
  price,
  abbreviationAmount,
  quantityLabel,
  originalPrice = null,
  showFromText = true,
  hasStar = true,
  hasCurrencySign = true,
}: PriceWithUnitAbbreviationProps) {
  const { t } = useTranslation();
  const showCentsInPrice = Boolean(price < SHOW_CENTS_TILL_AMOUNT);
  const showCentsInOriginalPrice = Boolean(originalPrice && originalPrice < SHOW_CENTS_TILL_AMOUNT);

  const containerClasses = classNames(
    'flex justify-between items-baseline',
    { 'gap-3': hasStar },
    { 'gap-2': !hasStar }
  );

  const starClass = classNames('text-xs text-gray-500 absolute left-[40px] w-2', {
    'top-[-5px] pl-0.5': showCentsInOriginalPrice,
    'top-[0px]': !showCentsInOriginalPrice,
  });

  return (
    <div className={containerClasses}>
      {originalPrice && originalPrice > 0 ? (
        <div className="flex-shrink-0 justify-items-start relative">
          <OriginalPrice
            price={originalPrice}
            hasCurrencySign={hasCurrencySign}
            showCents={showCentsInOriginalPrice}
          />
          {hasStar && <span className={starClass}>*</span>}
        </div>
      ) : null}
      <div className="flex-grow text-right">
        {showFromText && (
          <span className="text-purple text-xs pr-1">{t('app.ui.fromprice.shorthand')}</span>
        )}
        <span className="">
          <CurrentPrice
            price={price}
            hasCurrencySign={hasCurrencySign}
            showCents={showCentsInPrice}
          />
        </span>
        {abbreviationAmount && (
          <span className="pl-1 inline-flex">
            <Abbreviation quantityLabel={quantityLabel} abbreviationAmount={abbreviationAmount} />
          </span>
        )}
      </div>
    </div>
  );
}
