import { ProductCardSizes } from 'types/productCard';
import { PRODUCT_CARD_SIZES, SIZE_CUTOFF_POINTS } from 'constants/productCard/productCard';
import { ProductBoxInfoPlacementTypes } from 'components/product/ProductBox';

export const getSizeLabel = (width: number): ProductCardSizes => {
  switch (true) {
    case width <= SIZE_CUTOFF_POINTS.XS_MAX:
      return PRODUCT_CARD_SIZES.XS;
    case width > SIZE_CUTOFF_POINTS.XS_MAX && width <= SIZE_CUTOFF_POINTS.S_MAX:
      return PRODUCT_CARD_SIZES.S;
    case width > SIZE_CUTOFF_POINTS.S_MAX && width <= SIZE_CUTOFF_POINTS.M_MAX:
      return PRODUCT_CARD_SIZES.M;
    case width > SIZE_CUTOFF_POINTS.M_MAX && width <= SIZE_CUTOFF_POINTS.L_MAX:
      return PRODUCT_CARD_SIZES.L;
    default:
      return PRODUCT_CARD_SIZES.XL;
  }
};

export const PRODUCT_INFO_PLACEMENT_TYPES = {
  UNDER_IMAGE: 'underImage',
  ON_TOP_OF_IMAGE: 'onTopOfImage',
};

const placementTypeUnderImage: Set<ProductCardSizes> = new Set([
  PRODUCT_CARD_SIZES.XS,
  PRODUCT_CARD_SIZES.S,
]);

const placementTypeOnTopOfImage: Set<ProductCardSizes> = new Set([
  PRODUCT_CARD_SIZES.XS,
  PRODUCT_CARD_SIZES.S,
  PRODUCT_CARD_SIZES.M,
]);

export const productCardSizesWithSmallDagpakkerSticker: Map<
  ProductBoxInfoPlacementTypes,
  Set<ProductCardSizes>
> = new Map([
  [PRODUCT_INFO_PLACEMENT_TYPES.UNDER_IMAGE, placementTypeUnderImage],
  [PRODUCT_INFO_PLACEMENT_TYPES.ON_TOP_OF_IMAGE, placementTypeOnTopOfImage],
]);

export const productCardSizesWithTimer: Set<ProductCardSizes> = new Set([
  PRODUCT_CARD_SIZES.M,
  PRODUCT_CARD_SIZES.L,
  PRODUCT_CARD_SIZES.XL,
]);

export const productCardSizesWithOriginalPercentage: Set<ProductCardSizes> = new Set([
  PRODUCT_CARD_SIZES.M,
  PRODUCT_CARD_SIZES.L,
  PRODUCT_CARD_SIZES.XL,
]);
