import { HtmlTransformer } from 'lib/HtmlTransformers/HtmlTransformerChainHandler';
import React, { ReactNode } from 'react';
import { Node } from 'lib/HtmlTransformers/Transform';

export default class LiTransformer implements HtmlTransformer {
  transform(node: Node, dataOrChildren: ReactNode, index: number): React.ReactElement {
    return <li key={`li${index}`}>{dataOrChildren}</li>;
  }

  doesTransform(node: Node): boolean {
    return node.type === 'tag' && node.name === 'li';
  }
}
