import { HtmlTransformer } from 'lib/HtmlTransformers/HtmlTransformerChainHandler';
import React, { ReactNode } from 'react';
import { Node } from 'lib/HtmlTransformers/Transform';

export default class BrTransformer implements HtmlTransformer {
  transform(node: Node, dataOrChildren: ReactNode, index: number): React.ReactElement {
    return <br key={`br${index}`} />;
  }

  doesTransform(node: Node): boolean {
    return node.type === 'tag' && node.name === 'br';
  }
}
