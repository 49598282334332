import { Quantity } from 'components/cartSlideOver/Quantity';
import { useAppDispatch, useAppSelector } from 'redux/appStore';
import {
  getAddToCartProduct,
  getAddToCartVariant,
  getAddToCartQuantity,
  setQuantity as setAddToCartQuantity,
} from 'redux/cart/addToCart.slice';

interface Props {
  isEnabled?: boolean;
}

export function AddToCartQuantity({ isEnabled }: Props) {
  const product = useAppSelector(getAddToCartProduct);
  const variant = useAppSelector(getAddToCartVariant);
  const quantity = useAppSelector(getAddToCartQuantity);
  const dispatch = useAppDispatch();
  const setQuantity = (q: number) => dispatch(setAddToCartQuantity(q));

  if (!product || !variant) return null;

  return (
    <Quantity
      product={product}
      variant={variant}
      quantity={quantity}
      isEnabled={isEnabled}
      setQuantity={setQuantity}
    />
  );
}
