import { useContext } from 'react';
import { ResizeContext } from 'src/contexts/Resize/ResizeContext';

export const useResize = () => {
  const context = useContext(ResizeContext);

  if (!context) {
    throw new Error('useResize must be used within a ResizeProvider');
  }

  return context;
};
