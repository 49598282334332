import { ProductCardSizes } from 'types/productCard';

export const PRODUCT_CARD_SIZES = {
  XS: 'xs',
  S: 's',
  M: 'm',
  L: 'l',
  XL: 'xl',
} as const;

const XS_MIN = 173;
const XS_MAX = 219;

const S_MIN = 220;
const S_MAX = 259;

const M_MIN = 260;
const M_MAX = 400;

const L_MIN = 401;
const L_MAX = 600;

const XL_MIN = 601;
const XL_MAX = 10000;

export const PRODUCT_CARD_DIMENSIONS: {
  [K in ProductCardSizes]: { minWidth: number; maxWidth: number };
} = {
  [PRODUCT_CARD_SIZES.XS]: {
    minWidth: XS_MIN,
    maxWidth: XS_MAX,
  } as const,
  [PRODUCT_CARD_SIZES.S]: {
    minWidth: S_MIN,
    maxWidth: S_MAX,
  } as const,
  [PRODUCT_CARD_SIZES.M]: {
    minWidth: M_MIN,
    maxWidth: M_MAX,
  } as const,
  [PRODUCT_CARD_SIZES.L]: {
    minWidth: L_MIN,
    maxWidth: L_MAX,
  } as const,
  [PRODUCT_CARD_SIZES.XL]: {
    minWidth: XL_MIN,
    maxWidth: XL_MAX,
  } as const,
} as const;

export const SIZE_CUTOFF_POINTS = {
  XS_MAX,
  S_MAX,
  M_MAX,
  L_MAX,
} as const;
