import React, { ReactNode } from 'react';
import TransformerChainHandler from 'lib/HtmlTransformers/HtmlTransformerChainHandler';
import ATransformer from 'lib/HtmlTransformers/ElementTransformers/ATransformer';
import BrTransformer from 'lib/HtmlTransformers/ElementTransformers/BrTransformer';
import DivTransformer from 'lib/HtmlTransformers/ElementTransformers/DivTransformer';
import EmTransformer from 'lib/HtmlTransformers/ElementTransformers/EmTransformer';
import H2Transformer from 'lib/HtmlTransformers/ElementTransformers/H2Transformer';
import H3Transformer from 'lib/HtmlTransformers/ElementTransformers/H3Transformer';
import H4Transformer from 'lib/HtmlTransformers/ElementTransformers/H4Transformer';
import IFrameTransformer from 'lib/HtmlTransformers/ElementTransformers/IframeTransformer';
import ImgTransformer from 'lib/HtmlTransformers/ElementTransformers/ImgTransformer';
import LiTransformer from 'lib/HtmlTransformers/ElementTransformers/LiTransformer';
import OlTransformer from 'lib/HtmlTransformers/ElementTransformers/OlTransformer';
import PTransformer from 'lib/HtmlTransformers/ElementTransformers/PTransformer';
import ScriptTransformer from 'lib/HtmlTransformers/ElementTransformers/ScriptTransformer';
import SpanTransformer from 'lib/HtmlTransformers/ElementTransformers/SpanTransformer';
import EmptyStringNodeTransformer from 'lib/HtmlTransformers/ElementTransformers/EmptyStringNodeTransformer';
import StrongTransformer from 'lib/HtmlTransformers/ElementTransformers/StrongTransformer';
import TableTransformer from 'lib/HtmlTransformers/ElementTransformers/TableTransformer';
import TbodyTransformer from 'lib/HtmlTransformers/ElementTransformers/TbodyTransformer';
import TdTransformer from 'lib/HtmlTransformers/ElementTransformers/TdTransformer';
import ThTransformer from 'lib/HtmlTransformers/ElementTransformers/ThTransformer';
import TrTransformer from 'lib/HtmlTransformers/ElementTransformers/TrTransformer';
import UlTransformer from 'lib/HtmlTransformers/ElementTransformers/UlTransformer';
import TheadTransformer from 'lib/HtmlTransformers/ElementTransformers/TheadTransformer';
import TfootTransformer from 'lib/HtmlTransformers/ElementTransformers/TfootTransformer';
import CaptionTransformer from 'lib/HtmlTransformers/ElementTransformers/CaptionTransformer';
import NodeSanitizerChainHandler from 'lib/HtmlTransformers/NodeSanitizerChainHandler';
import InlineStyleSanitizer from 'lib/HtmlTransformers/NodeSanitizers/InlineStyleSanitizer';

export interface Node {
  type: string;
  name: string;
  children: Node[];
  next: Node | null;
  prev: Node | null;
  parent: Node | null;
  data: ReactNode;
  attribs?: any;
}

const sanitizeChainHandler = new NodeSanitizerChainHandler([new InlineStyleSanitizer()]);

const transformerChainHandler = new TransformerChainHandler(
  [
    new EmptyStringNodeTransformer(),
    new DivTransformer(),
    new PTransformer(),
    new SpanTransformer(),
    new ATransformer(),
    new H2Transformer(),
    new H3Transformer(),
    new StrongTransformer(),
    new LiTransformer(),
    new UlTransformer(),
    new EmTransformer(),
    new OlTransformer(),
    new ImgTransformer(),
    new H4Transformer(),
    new IFrameTransformer(),
    new TdTransformer(),
    new TrTransformer(),
    new ThTransformer(),
    new TableTransformer(),
    new TheadTransformer(),
    new TfootTransformer(),
    new CaptionTransformer(),
    new TbodyTransformer(),
    new BrTransformer(),
    new ScriptTransformer(),
  ],
  new SpanTransformer()
);

export default function transform(
  node: Node,
  index: number,
  isMobile: boolean
): React.ReactElement | null {
  const dataOrChildren: ReactNode =
    node.data || node.children.map((node: Node, index: number) => transform(node, index, isMobile));

  const sanitizedNode = sanitizeChainHandler.handle(node);

  return transformerChainHandler.handle(sanitizedNode, dataOrChildren, index, isMobile);
}
