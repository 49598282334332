import { ProductCardL } from 'components/productCard/l/ProductCardL';
import { ProductCardXS } from 'components/productCard/xs/ProductCardXS';
import { ProductCardS } from 'components/productCard/s/ProductCardS';
import { ProductCardM } from 'components/productCard/m/ProductCardM';
import { useProductBoxSize } from 'hooks/product/box/useProductBoxSizes';
import { PRODUCT_CARD_SIZES } from 'constants/productCard/productCard';
import { ProductCardXL } from 'components/productCard/xl/ProductCardXL';

import { ProductCardProps, ProductCardSizes } from 'types/productCard';

type Props = {
  initialRenderSize: ProductCardSizes;
} & ProductCardProps;

export const ResponsiveProductCard = ({ initialRenderSize, ...props }: Props) => {
  const { ref, size } = useProductBoxSize<HTMLDivElement>({
    initialRenderSize,
  });

  return (
    <div ref={ref}>
      {size === PRODUCT_CARD_SIZES.XS && <ProductCardXS {...props} />}
      {size === PRODUCT_CARD_SIZES.S && <ProductCardS {...props} />}
      {size === PRODUCT_CARD_SIZES.M && <ProductCardM {...props} />}
      {size === PRODUCT_CARD_SIZES.L && <ProductCardL {...props} />}
      {size === PRODUCT_CARD_SIZES.XL && <ProductCardXL {...props} />}
    </div>
  );
};
