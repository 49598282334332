import { useContext } from 'react';
import { NotificationContext } from 'src/contexts/notifications/ToastContext';

export function useNotificationToast() {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
}

export default useNotificationToast;
