import { useTranslation } from 'react-i18next';
import { TrackingProductList } from 'types/tracking';
import { Product } from 'types/types';
import PriceWithUnitAbbreviation from '../price/PriceWithUnitAbbreviation';
import AddToCart from './AddToCart';
import ProductLocationPin from './ProductLocationPin';
import classNames from 'classnames';
import { ProductBoxInfoPlacementTypes } from 'components/product/ProductBox';
import { getAbbreviationAmount } from 'lib/abbreviation/abbreviation';
import { CountdownDisplay } from 'components/countdown/CountdownDisplay';
import { PRODUCT_INFO_PLACEMENT_TYPES } from 'lib/productCard/productCard';

interface ProductInfoProps {
  showAddToCartButton?: boolean;
  product: Product;
  productPosition: number;
  productList?: TrackingProductList;
  isRecommended?: boolean;
  showLocationPin?: boolean;
  productDetailPlacement?: ProductBoxInfoPlacementTypes;
  counterEndDate: Date | null;
}

export const ProductInfo = ({
  showAddToCartButton,
  product,
  productList,
  productPosition,
  productDetailPlacement = PRODUCT_INFO_PLACEMENT_TYPES.UNDER_IMAGE,
  isRecommended = false,
  showLocationPin = true,
  counterEndDate = null,
}: ProductInfoProps) => {
  const { t } = useTranslation();

  const descriptionHeight = classNames({
    'h-14 md:h-16': productDetailPlacement === PRODUCT_INFO_PLACEMENT_TYPES.UNDER_IMAGE,
  });

  const priceContainerClass = classNames({
    'flex-grow': !counterEndDate,
    'text-left': counterEndDate,
  });

  return (
    <>
      <div className={descriptionHeight}>
        <header>
          <h3
            key={product.id}
            className="md:min-h-8 text-left text-sm md:text-base font-semibold mb-2 overflow-hidden text-gray-700 align-text-top cursor-pointer line-clamp-2"
            data-aq={'dealTitle'}
          >
            {product.name}
          </h3>
        </header>
        {showLocationPin && (
          <div className="h-4">
            <ProductLocationPin product={product} />
          </div>
        )}
      </div>
      <footer className="flex basis-1/3 items-start justify-between mt-2 gap-1">
        {product.hasExternalBooking ? (
          <>
            <div className="flex-grow"></div>
            {showAddToCartButton && (
              <div className="text-purple border-purple rounded-md cursor-pointer border-2 py-2 px-6 text-sm hover:bg-purple hover:text-white hover:border-transparent">
                {t('app.ui.product.show_prices')}
              </div>
            )}
          </>
        ) : (
          <>
            {counterEndDate && (
              <div className="flex-grow min-w-48">
                <CountdownDisplay endDate={counterEndDate} />
              </div>
            )}
            <div className={priceContainerClass}>
              <PriceWithUnitAbbreviation
                price={product.defaultVariant.price}
                originalPrice={product.defaultVariant.originalPrice}
                showFromText={product.variants.length > 1}
                hasCurrencySign={false}
                abbreviationAmount={getAbbreviationAmount(product)}
                quantityLabel={product.defaultVariant.quantityLabel}
              />
            </div>
            {showAddToCartButton && (
              <div className="ml-2">
                <AddToCart
                  product={product}
                  productList={productList}
                  isRecommended={isRecommended}
                  productPosition={productPosition}
                />
              </div>
            )}
          </>
        )}
      </footer>
    </>
  );
};
