import { HtmlTransformer } from 'lib/HtmlTransformers/HtmlTransformerChainHandler';
import React, { ReactNode } from 'react';
import { Node } from 'lib/HtmlTransformers/Transform';

export default class DivTransformer implements HtmlTransformer {
  transform(node: Node, dataOrChildren: ReactNode, index: number): React.ReactElement {
    return (
      <div
        key={`div${index}`}
        id={node.attribs?.id}
        data-checkin-product-id={node.attribs?.['data-checkin-product-id']}
      >
        {dataOrChildren}
      </div>
    );
  }

  doesTransform(node: Node): boolean {
    return node.type === 'tag' && node.name === 'div';
  }
}
