import { useCallback, useState } from 'react';
import { formatDateForApi } from 'lib/date';
import { useAppDispatch, useAppSelector } from 'redux/appStore';
import {
  getActiveStartDate,
  getAvailableDates,
  getAvailableTimeslots,
  getIsReservationAvailable,
  getPreviousReservation,
  getSelectedDate,
  getSelectedTimeslot,
  getVariantId,
  selectReservationIsLoading,
  setActiveStartDate,
  setAvailableDates,
  setAvailableTimeslots,
  setIsReservationAvailable,
  setReservationError,
  setReservationSelectedQuantity,
  setSelectedDate,
  setSelectedTimeslot,
  setVariantId,
} from 'redux/cart/reservation.slice';
import {
  getAddToCartQuantity,
  getAddToCartVariant,
  setAddToCartProduct,
  setAddToCartVariant,
  setQuantity,
  setShowSlider,
} from 'redux/cart/addToCart.slice';
import isReservationEnabled from 'lib/reservation/isReservationEnabled';
import { Product, Variant } from 'types/product';
import { computeMinimumQuantity } from 'lib/cart/cart';
import { fetchAvailableDates, fetchAvailableTimeslots } from 'redux/cart/reservation.thunks';

interface OptionalReservationProps {
  product?: Product;
  quantity?: number;
  variant?: Variant | null;
  startDate?: string;
  date?: string;
  time?: string;
  showFirstAvailability?: boolean;
}

export default function useReservationFlow() {
  const dispatch = useAppDispatch();

  const variantId = useAppSelector(getVariantId);
  const isReservationAvailable = useAppSelector(getIsReservationAvailable);
  const selectedDate = useAppSelector(getSelectedDate);
  const isReservationLoading = useAppSelector(selectReservationIsLoading);
  const selectedTimeslot = useAppSelector(getSelectedTimeslot);
  const activeStartDate = useAppSelector(getActiveStartDate);
  const availableDates = useAppSelector(getAvailableDates);
  const availableTimeslots = useAppSelector(getAvailableTimeslots);
  const _variant = useAppSelector(getAddToCartVariant);
  const _quantity = useAppSelector(getAddToCartQuantity);
  const previousReservation = useAppSelector(getPreviousReservation);

  const [isLoadingCalendar, setIsLoadingCalendar] = useState<boolean>(false);
  const [isLoadingTimeslots, setIsLoadingTimeslots] = useState<boolean>(false);

  const setupAvailability = useCallback(
    async ({ startDate, variant, quantity, showFirstAvailability }: OptionalReservationProps) => {
      const start = startDate ?? formatDateForApi(new Date());
      if (!activeStartDate) dispatch(setActiveStartDate(start));

      await dispatch(
        fetchAvailableDates({
          variantId: (variant || (_variant as Variant)).id,
          quantity: quantity || _quantity,
          date: start,
          showFirstAvailability,
        })
      );
      setIsLoadingCalendar(false);
    },
    [_quantity, _variant, activeStartDate, dispatch]
  );

  const fetchTimeSlots = useCallback(
    async ({ date, quantity, variant }: OptionalReservationProps) => {
      await dispatch(
        fetchAvailableTimeslots({
          date: date || (selectedDate ?? formatDateForApi(selectedDate)),
          variantId: (variant || (_variant as Variant)).id,
          quantity: quantity || _quantity,
        })
      );
      setIsLoadingTimeslots(false);
    },
    [_quantity, _variant, dispatch, selectedDate]
  );

  const dispatchInitialState = useCallback(
    async (variant: Variant) => {
      if (variant?.id !== variantId) {
        dispatch(setVariantId(variant.id));
        dispatch(setIsReservationAvailable(isReservationEnabled(variant)));
      }
      dispatch(setReservationError(false));
      // reset values only if present
      if (!isLoadingCalendar) {
        if (availableDates) dispatch(setAvailableDates([]));
        if (availableTimeslots) dispatch(setAvailableTimeslots([]));
        if (selectedDate && !previousReservation) dispatch(setSelectedDate(null));
        if (selectedTimeslot && !previousReservation) dispatch(setSelectedTimeslot(null));
      }
    },
    [
      availableDates,
      availableTimeslots,
      dispatch,
      isLoadingCalendar,
      previousReservation,
      selectedDate,
      selectedTimeslot,
      variantId,
    ]
  );

  const dispatchInitialStateForTimeslots = useCallback(
    async (variant: Variant | null) => {
      dispatch(setAvailableTimeslots([]));
      dispatch(setReservationError(false));
      if (variant?.id !== variantId) dispatch(setVariantId(variantId));
      if (!isLoadingCalendar && !previousReservation) dispatch(setSelectedTimeslot(null));
    },
    [dispatch, isLoadingCalendar, previousReservation, variantId]
  );

  // initial choice can be:
  // 1. active start date 2. variant 3. quantity
  const updateAvailabilityCalendar = useCallback(
    async ({
      variant,
      quantity,
      startDate,
      showFirstAvailability = false,
    }: OptionalReservationProps) => {
      const v = variant || (_variant as Variant);
      const start = startDate || activeStartDate || formatDateForApi(new Date());
      if (!v || isLoadingCalendar) return;
      setIsLoadingCalendar(true);

      await dispatchInitialState(v);
      // if reservation flow is disabled for variant, no need to continue
      if (!isReservationEnabled(v)) return;
      await setupAvailability({
        variant,
        quantity,
        startDate: start as string,
        showFirstAvailability,
      });

      setIsLoadingCalendar(false);
    },
    [_variant, activeStartDate, dispatchInitialState, isLoadingCalendar, setupAvailability]
  );

  const updateAvailableTimeslots = useCallback(
    async ({ date, variant, quantity }: OptionalReservationProps) => {
      const v = variant || (_variant as Variant);
      if (!v || isLoadingTimeslots) return;
      setIsLoadingTimeslots(true);

      await dispatchInitialStateForTimeslots(v);
      await fetchTimeSlots({ variant: v, date, quantity });

      setIsLoadingTimeslots(false);
    },
    [_variant, dispatchInitialStateForTimeslots, fetchTimeSlots, isLoadingTimeslots]
  );

  const dispatchInitialEditState = ({
    product,
    variant,
    quantity,
  }: {
    product: Product;
    variant: Variant;
    quantity: number;
  }) => {
    const minimumQuantity = computeMinimumQuantity({ choosenQuantity: quantity, product });
    dispatch(setIsReservationAvailable(true));
    dispatch(setAddToCartProduct(product));
    dispatch(setAddToCartVariant(variant ?? product.variants[0]));
    dispatch(setVariantId(variant.id));
    dispatch(setQuantity(minimumQuantity));
    dispatch(setReservationSelectedQuantity(minimumQuantity));
  };

  const setupEditSlider = async ({
    product,
    variant,
    quantity,
    date,
    time,
  }: Required<Omit<OptionalReservationProps, 'startDate' | 'showFirstAvailability'>>) => {
    dispatchInitialEditState({ product, variant: variant as Variant, quantity });
    dispatch(setActiveStartDate(date));

    await setupAvailability({ variant, startDate: date, quantity, showFirstAvailability: false });
    dispatch(setSelectedDate(date));

    await fetchTimeSlots({ date, quantity, variant });
    dispatch(setSelectedTimeslot(time));

    dispatch(setReservationError(false));
    dispatch(setShowSlider(true));
  };

  const setupEditSliderWithoutReservation = async ({
    product,
    variant,
    quantity,
  }: Required<Pick<OptionalReservationProps, 'product' | 'variant' | 'quantity'>>) => {
    dispatchInitialEditState({ product, variant: variant as Variant, quantity });

    await setupAvailability({ variant, quantity });
  };

  return {
    isReservationAvailable,
    isLoading: isLoadingCalendar || isLoadingTimeslots || isReservationLoading,
    isLoadingCalendar,
    isLoadingTimeslots,
    updateAvailabilityCalendar,
    updateAvailableTimeslots,
    setupEditSlider,
    setupEditSliderWithoutReservation,
  };
}
