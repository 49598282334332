import * as React from 'react';
import { PreviousSlideControl } from './PreviousSlideControl';
import { SlideControl } from './SlideControl';
import { NextSlideControl } from './NextSlideControl';

export function Indicator(props: {
  index: number;
  currentSlide: number;
  onPreviousControlClick: () => void;
  onNextControlClick: () => void;
  onControlClick: (e: React.MouseEvent | React.KeyboardEvent) => void;
  selected: boolean;
  label: string;
  totalIndicators: number;
}) {
  return (
    <>
      <PreviousSlideControl
        index={props.index}
        currentSlide={props.currentSlide}
        onPreviousControlClick={props.onPreviousControlClick}
      />
      <SlideControl
        index={props.index}
        isSelected={props.selected}
        onClick={props.onControlClick}
        label={props.label}
      />
      <NextSlideControl
        index={props.index}
        currentSlide={props.currentSlide}
        totalIndicators={props.totalIndicators}
        onNextControlClick={props.onNextControlClick}
      />
    </>
  );
}
