import { PluginInputType, PluginComponent } from 'lib/react-with-native-form';
import React from 'react';

const TextAreaInput: PluginComponent<TextAreaInputType> = ({ value, extra, onChange }) => {
  return (
    <textarea
      rows={extra?.rows || 4}
      className="block w-full px-4 py-3 text-gray-900 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
      value={value}
      onChange={(event) => {
        onChange(event.target.value);
      }}
    />
  );
};
TextAreaInput.defaultInitialValue = '';

export class TextAreaInputType implements PluginInputType {
  value: string;
  config?: {
    errorClassName?: string;
    extraClassName?: string;
    replaceClassName?: string;
  };
  extra?: {
    rows: number;
  };
}

export default TextAreaInput;
