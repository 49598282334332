import React, { ReactNode } from 'react';
import { Node } from 'lib/HtmlTransformers/Transform';

export interface HtmlTransformer {
  transform(
    node: Node,
    dataOrChildren: ReactNode,
    index: number,
    isMobile: boolean
  ): React.ReactElement | null;
  doesTransform(node: Node, dataOrChildren: ReactNode): boolean;
}

export default class TransformerChainHandler {
  private readonly transformers: HtmlTransformer[];
  private readonly fallbackTransformer: HtmlTransformer;

  constructor(transformers: HtmlTransformer[], fallbackTransformer: HtmlTransformer) {
    this.transformers = transformers;
    this.fallbackTransformer = fallbackTransformer;
  }

  handle(
    node: Node,
    dataOrChildren: ReactNode,
    index: number,
    isMobile: boolean
  ): React.ReactElement | null {
    for (const transformer of this.transformers) {
      if (!transformer.doesTransform(node, dataOrChildren)) {
        continue;
      }

      return transformer.transform(node, dataOrChildren, index, isMobile);
    }

    return this.fallbackTransformer.transform(node, dataOrChildren, index, isMobile);
  }
}
