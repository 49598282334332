import { Product } from 'types/product';

export const getOriginalSavingPercentage = ({
  product,
  maximumAcceptableRate,
}: {
  product: Product;
  maximumAcceptableRate: number | null;
}) => {
  const discountRate = product.dagpakkerDetails?.originalDiscountRate;

  if (!discountRate) return null;

  if (maximumAcceptableRate && discountRate >= maximumAcceptableRate) return null;

  return discountRate;
};
