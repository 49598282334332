import { HtmlTransformer } from 'lib/HtmlTransformers/HtmlTransformerChainHandler';
import { ReactNode } from 'react';
import { Node } from 'lib/HtmlTransformers/Transform';
import IframeResizer from 'iframe-resizer-react';
import classNames from 'classnames';

const widthMobileKey = 'width-mobile';
const widthDesktopKey = 'width-desktop';
const heightMobileKey = 'height-mobile';
const heightDesktopKey = 'height-desktop';

const getHeight = (node: Node, isMobile: boolean): string | undefined => {
  return isMobile ? node.attribs[heightMobileKey] : node.attribs[heightDesktopKey];
};

const getWidth = (node: Node, isMobile: boolean): string | undefined => {
  return isMobile ? node.attribs[widthMobileKey] : node.attribs[widthDesktopKey];
};

export default class IFrameTransformer implements HtmlTransformer {
  transform(
    node: Node,
    dataOrChildren: ReactNode,
    index: number,
    isMobile: boolean
  ): React.ReactElement {
    const height = getHeight(node, isMobile);
    const width = getWidth(node, isMobile);

    const containerClassName = classNames(
      'bg-white sm:mx-0 p-4 md:rounded-md shadow-default max-w-full',
      {
        [`w-[${width}px]`]: width,
        [`h-[${height}px]`]: height,
        ['w-full']: !width,
        ['h-full']: !height,
      }
    );

    const iframeClassNames = classNames('', {
      'w-full': !width,
      'h-full': !height,
    });

    return (
      <div key={`iframe${index}`} className={containerClassName}>
        <IframeResizer
          id={node.attribs.name}
          name={node.attribs.name}
          scrolling={node.attribs.scrolling}
          src={node.attribs.src}
          height={height}
          width={width}
          className={iframeClassNames}
        />
      </div>
    );
  }

  doesTransform(node: Node): boolean {
    return node.type === 'tag' && node.name === 'iframe';
  }
}
