import { HtmlTransformer } from 'lib/HtmlTransformers/HtmlTransformerChainHandler';
import React, { ReactNode } from 'react';
import { Node } from 'lib/HtmlTransformers/Transform';

export default class ThTransformer implements HtmlTransformer {
  transform(node: Node, dataOrChildren: ReactNode, index: number): React.ReactElement {
    return <th key={`th${index}`}>{dataOrChildren}</th>;
  }

  doesTransform(node: Node): boolean {
    return node.type === 'tag' && node.name === 'th';
  }
}
