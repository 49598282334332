import { getProductImages } from 'lib/products/product';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import Image from 'next/image';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCartOutlined';
import useResponsive from '../../hooks/useResponsive';
import BigButton from '../BigButton';
import { RecommendedProductsSlider } from '../productList/RecommendedProductsSlider';
import { useAppSelector } from 'redux/appStore';
import {
  getAddToCartProduct,
  getAddToCartVariant,
  getQuantityLabel,
} from 'redux/cart/addToCart.slice';
import { RecommendedProductLocation } from 'constants/product';
import PriceHorizontally from '../price/PriceHorizontally';
import Abbreviation from 'components/price/Abbreviation';
import { getAbbreviationAmount } from 'lib/abbreviation/abbreviation';

interface AddToCartStepProps {
  onClose: Function;
}

export default function SubmittedStep({ onClose }: AddToCartStepProps) {
  const { isLg } = useResponsive();
  const { t } = useTranslation();
  const router = useRouter();
  const product = useAppSelector(getAddToCartProduct);
  const variant = useAppSelector(getAddToCartVariant);
  const selectedQuantityLabel = useAppSelector(getQuantityLabel);

  const abbreviationAmount = product && getAbbreviationAmount(product);

  const goToCartAndCloseSlideOver = async () => {
    await router.push('/cart');
  };

  if (!product || !variant) return <></>;

  const images = getProductImages(product, variant);

  return (
    <>
      <div className="relative mt-6 flex px-4 sm:px-6">
        <div className="h-full border-b-2 pb-2" aria-hidden="true">
          <div className="flex">
            <Image
              className="flex-1 object-cover object-center h-auto mr-4 rounded"
              src={images[0].src}
              alt={variant.name}
              height={isLg ? 150 : 75}
              width={isLg ? 220 : 110}
              quality={isLg ? 100 : 75}
            />
            <div className="flex-initial mx-2 font-bold break-normal">{variant.name}</div>
          </div>
          <div className={'flex justify-center items-end mt-3'}>
            <PriceHorizontally
              price={variant.price}
              originalPrice={variant.originalPrice}
              showFromText={false}
              hasStar={true}
            />
            <div>
              {abbreviationAmount && (
                <div>
                  <Abbreviation
                    quantityLabel={selectedQuantityLabel}
                    abbreviationAmount={abbreviationAmount}
                  />
                </div>
              )}
            </div>
          </div>
          <BigButton
            dataTestId={'sliderGoToCartButton'}
            icon={ShoppingCartIcon}
            title={t('app.ui.cart_slider.view_cart')}
            className={
              'w-full px-2 flex justify-center font-bold text-base rounded-md py-2 cursor-pointer mt-3'
            }
            onClick={goToCartAndCloseSlideOver}
          />
          <a
            className={'text-orange font-bold flex justify-center mt-3 cursor-pointer'}
            onClick={() => onClose()}
          >
            {t('app.ui.cart_slider.continue_shopping')}
          </a>
        </div>
      </div>

      {/* the pb-28 is a fix from rene to allow scrolling */}
      <div className="p-4">
        <RecommendedProductsSlider
          title={t('app.ui.cart_slider.recommended_products_title')}
          carouselContainerStyle={'pb-28'}
          location={RecommendedProductLocation.SLIDER}
        />
      </div>
    </>
  );
}
