import React, { useCallback, useEffect } from 'react';

const ExternalJavascript = ({ src }: { src?: string }) => {
  const addScriptToDom = useCallback(() => {
    if (!src) return null;
    // Is script already in DOM?
    if (document.querySelectorAll(`script[src="${src}"]`).length > 0) return;

    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.type = 'application/javascript';

    document.body.appendChild(script);
  }, [src]);

  const removeScriptFromDom = useCallback(() => {
    // Is script still needed in DOM due to other ExternalJavascript instances with same src?
    if (document.querySelectorAll(`div[data-javascript-src="${src}"]`).length > 0) return;

    document.querySelectorAll(`script[src="${src}"]`).forEach((script: any) => {
      document.body.removeChild(script);
    });
  }, [src]);

  useEffect(() => {
    addScriptToDom();

    // on unmounting
    return () => {
      removeScriptFromDom();
    };
  }, [addScriptToDom, removeScriptFromDom, src]);

  if (!src) return null;

  return <div data-javascript-src={src} className={'hidden'} />;
};

export default ExternalJavascript;
