import { ChevronDownIcon } from '@heroicons/react/solid';
import * as React from 'react';

export default function DropdownButton({
  onClick,
  label,
  maxLength,
}: {
  onClick: () => void;
  label: string;
  maxLength?: number;
}) {
  return (
    <div
      onClick={onClick}
      className="flex w-full px-2 py-3 text-sm font-medium text-gray-700 rounded-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
    >
      <div className="flex-1">
        {maxLength && label.length > maxLength ? label.slice(0, maxLength).concat('..') : label}
      </div>
      <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
    </div>
  );
}
