export default function DisabledSelectOption({
  index,
  label,
  tag,
}: {
  index: number;
  label: string;
  tag?: string | null;
}) {
  return (
    <>
      <div
        key={index}
        className="cursor-pointer items-center mb-2 cursor-not-allowed"
        onClick={(e) => e.preventDefault()}
      >
        <div className="flex flex-row flex-1 items-center">
          <label className="flex-1 text-t-primary opacity-50 whitespace-nowrap overflow-hidden text-ellipsis">
            {label}
          </label>
          {tag ? <div className="text-xs pl-3">{tag}</div> : null}
        </div>
      </div>
    </>
  );
}
