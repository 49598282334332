import { HtmlTransformer } from 'lib/HtmlTransformers/HtmlTransformerChainHandler';
import React, { ReactNode } from 'react';
import { Node } from 'lib/HtmlTransformers/Transform';
import ExternalJavascript from 'components/ExternalJavascript/ExternalJavascript';
import { ALLOWED_JAVASCRIPT_URLS_PARTS } from 'constants/enums';

export default class ScriptTransformer implements HtmlTransformer {
  transform(node: Node, dataOrChildren: ReactNode, index: number): React.ReactElement | null {
    if (node.attribs && typeof node.attribs?.src === 'string') {
      return <ExternalJavascript src={node.attribs.src} key={`javascript${index}`} />;
    }

    return null;
  }

  doesTransform(node: Node): boolean {
    return (
      node.name === 'script' &&
      typeof node.attribs !== 'undefined' &&
      typeof node.attribs.src === 'string' &&
      this.isAllowedJavascript(node.attribs.src)
    );
  }

  // Only allow JS scripts which have in their src attributes: allowedScriptSrcParts string[].
  private isAllowedJavascript(src: string): boolean {
    for (const value of Object.values(ALLOWED_JAVASCRIPT_URLS_PARTS)) {
      if (src.toLowerCase().includes(value.toLowerCase())) {
        return true;
      }
    }

    return false;
  }
}
