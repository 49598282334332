import { getCents, getEuros } from 'lib/price';
import CurrencySign from 'components/price/CurrencySign';

export type PresentationalPriceProps = {
  price: number;
  hasCurrencySign?: boolean;
  showCents?: boolean;
};

export default function OriginalPrice({
  price,
  hasCurrencySign,
  showCents = true,
}: PresentationalPriceProps) {
  const comma = showCents ? ',' : '';

  return (
    <div className="flex flex-col">
      <div className="flex">
        <div className="text-sm text-gray-500">
          {getEuros(price)}
          {comma}
        </div>
        {showCents && <div className="text-xs text-gray-500 align-top">{getCents(price)}</div>}
      </div>
      <div className="text-gray-500 text-[0.5rem]">Adviesprijs</div>
    </div>
  );
}
