import * as React from 'react';
import { Control } from './Control';

export function NextSlideControl(props: {
  index: number;
  currentSlide: number;
  totalIndicators: number;
  onNextControlClick: () => void;
}) {
  const isLastIndicator = props.index + 1 === props.totalIndicators;
  if (!isLastIndicator) {
    return null;
  }

  return (
    <Control
      onClick={props.onNextControlClick}
      disabled={props.currentSlide + 1 === props.totalIndicators}
      testId={'nextControl'}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#6E28FF"
        strokeWidth={3}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
      </svg>
    </Control>
  );
}
