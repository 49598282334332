import { useState, useEffect } from 'react';
import { calculateTimeLeft } from 'lib/time/time';

export interface TimeParts {
  hours: string;
  minutes: string;
  seconds: string;
}

const useCountdown = ({ endDate }: { endDate: Date }): TimeParts => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(endDate));

  useEffect(() => {
    const timerId = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(endDate));
    }, 1000);

    return () => clearTimeout(timerId);
  }, [timeLeft, endDate]);

  return timeLeft;
};

export default useCountdown;
