import React, { useCallback } from 'react';
import { classNames } from 'lib/classes';
import { useAppDispatch, useAppSelector } from 'redux/appStore';
import {
  getAvailableTimeslots,
  getSelectedDate,
  getSelectedTimeslot,
  setSelectedTimeslot,
} from 'redux/cart/reservation.slice';
import { convert12hTimeTo24h } from 'lib/date';

const AvailabilityTimeslots = () => {
  const availableSlots = useAppSelector(getAvailableTimeslots);
  const selectedTimeslot = useAppSelector(getSelectedTimeslot);
  const selectedDate = useAppSelector(getSelectedDate);
  const dispatch = useAppDispatch();

  const isTimeslotSelected = useCallback(
    (slot?: string): boolean => Boolean(slot) && slot === selectedTimeslot,
    [selectedTimeslot]
  );

  if (!availableSlots?.length || !selectedDate) return null;

  const handleOnClick = (slot: string) => {
    if (selectedTimeslot !== slot) dispatch(setSelectedTimeslot(slot));
  };

  return (
    <div className="timeslots grid grid-cols-3 gap-3 mx-auto">
      {availableSlots.map((slot) => (
        <div
          className={classNames(isTimeslotSelected(slot) ? 'active' : '', 'slot')}
          key={slot}
          onClick={() => handleOnClick(slot)}
        >
          {convert12hTimeTo24h(slot)}
        </div>
      ))}
    </div>
  );
};

export default AvailabilityTimeslots;
