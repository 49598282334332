import { BackendError, Cart, CartReservation, InsufficientStockError } from 'types/types';
import { doFetch, resolveApiUrl } from 'lib/api';
import { transformCartJson } from 'lib/transforms';
import { captureTypeErrorIfInvalid } from 'lib/error';
import API_ENDPOINTS from 'constants/routes/api';

export default async function updateCartItem({
  cartToken,
  cartItemId,
  quantity,
  reservation,
  authToken,
}: {
  cartToken: string;
  cartItemId: number;
  quantity: number;
  reservation?: CartReservation | null;
  authToken: string | null;
}): Promise<Cart | null> {
  const headers = {
    accept: 'application/json',
    'Content-Type': 'application/merge-patch+json',
  };

  if (authToken !== null) {
    Object.assign(headers, { 'X-AUTH-TOKEN': authToken });
  }

  const endpoint = resolveApiUrl(API_ENDPOINTS.UpdateCart(cartToken, cartItemId));
  const res = await doFetch(endpoint, {
    body: JSON.stringify({ quantity, reservation }),
    headers: headers,
    method: 'PATCH',
    redirect: 'follow',
    mode: 'cors',
  });

  if (!res.ok) {
    if (res.status === 404) {
      return null;
    }
    if (res.status === 400 || res.status === 422) {
      const result = await res.json();

      if (result.detail === 'app.change_quantity.variant_insufficient_stock') {
        throw new InsufficientStockError(result.violations);
      }

      throw new BackendError(result.violations);
    }
    throw new Error();
  }

  const result = await res.json();
  const cart = transformCartJson(result);
  captureTypeErrorIfInvalid('Cart', cart);

  return cart;
}
