import { Cart } from 'types/types';
import { doPost, resolveApiUrl } from 'lib/api';
import { transformCartJson } from 'lib/transforms';
import API_ENDPOINTS from 'constants/routes/api';

export default async function refreshCart(tokenValue: string): Promise<Cart> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.RefreshCart(tokenValue));
  const res = await doPost(endpoint, {});

  if (res.status === 404) {
    return Promise.reject('Cart not found');
  }

  const orderJson = await res.json();
  return transformCartJson(orderJson);
}
