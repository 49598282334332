import { MAX_PRODUCT_QUANTITY_PER_CUSTOMER } from 'constants/product';
import useReservationFlow from 'hooks/reservation/useReservationFlow';
import { buildCartReservation, computeMinimumQuantity } from 'lib/cart/cart';
import handleAddToCart, {
  canProductBeAddedToCart,
  getQuantityOfProductInCart,
} from 'lib/cart/handleAddToCart';
import handleEditCartItem from 'lib/cart/handleEditCartItem';
import { castToVariantOrNull } from 'lib/products/castToVariantOrNull';
import isReservationEnabled from 'lib/reservation/isReservationEnabled';
import validation from 'lib/validation';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'redux/appStore';
import {
  getAddToCartContext,
  getAddToCartProduct,
  resetAddToCart,
  setAddToCartProduct,
  setAddToCartVariant,
  setPreviousProduct,
  setProductList,
  setQuantity,
  setShowSlider,
  setSubmitted,
  setSubmitting,
} from 'redux/cart/addToCart.slice';
import {
  getComments,
  getPhone,
  getSelectedDate,
  getSelectedTimeslot,
  resetReservation,
} from 'redux/cart/reservation.slice';
import { TrackingProductList } from 'types/tracking';
import { Product, Variant } from 'types/types';
import useStore from '../store';
import useSession from './useSession';
import useCartReminderViewCount from 'hooks/cartItem/useCartReminderViewCount';

const useCart = () => {
  const { t } = useTranslation();
  const { session } = useSession();
  const [cart, setCart] = useStore('cart');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const { submitting, productList, isEdit, editOrderItemId } = useAppSelector(getAddToCartContext);
  const selectedDate = useAppSelector(getSelectedDate);
  const selectedTimeslot = useAppSelector(getSelectedTimeslot);
  const addToCartProduct = useAppSelector(getAddToCartProduct);
  const phone = useAppSelector(getPhone);
  const comments = useAppSelector(getComments);
  const { updateAvailabilityCalendar } = useReservationFlow();
  const { resetCounter } = useCartReminderViewCount();

  const validateReserveNow = (): string | null => {
    setErrorMessage(null);
    if (!selectedDate) return t('app.ui.reservation.error.date');
    if (!selectedTimeslot) return t('app.ui.reservation.error.timeslot');
    if (!validation.isValidPhone(phone)) return t('app.ui.reservation.error.phone_number');
    return null;
  };

  const submitAddToCartContext = async (
    product: Product | null,
    variant: Variant | null,
    quantity: number,
    isReserveLater?: boolean,
    bookingExternalId?: string
  ) => {
    setErrorMessage(null);
    dispatch(setSubmitted(false));
    if (!product || submitting) return;
    resetCounter();

    dispatch(setSubmitting(true));
    try {
      const reservation =
        !isReserveLater && selectedDate && selectedTimeslot
          ? buildCartReservation({
              date: selectedDate,
              time: selectedTimeslot,
              phone,
              comments,
              quantity,
            })
          : undefined;

      if (isEdit) {
        await handleEditCartItem({
          cart,
          authToken: session ? session.token : null,
          orderItemId: editOrderItemId as number,
          quantity,
          reservation,
          setCart,
          t,
        });
        // after successful edit, we want to close the slider and reset ATC states
        dispatch(setShowSlider(false));
        dispatch(resetReservation());
        dispatch(resetAddToCart());
      } else {
        await handleAddToCart({
          cart,
          product,
          variant: variant ?? product.variants[0],
          quantity,
          setCart,
          productList: productList || null,
          t,
          authToken: session ? session.token : null,
          reservation,
          bookingExternalId,
        });
        dispatch(setSubmitted(true));
      }
    } catch (errorMessage) {
      if (typeof errorMessage === 'string') {
        setErrorMessage(errorMessage);
      }
      // explicitly throw error
      throw errorMessage;
    } finally {
      dispatch(setSubmitting(false));
    }
  };

  const openSlideOver = async ({
    product,
    variant,
    productList,
    quantity,
    isRecommended = false,
  }: {
    product: Product;
    variant?: Variant;
    quantity?: number;
    productList?: TrackingProductList;
    isRecommended?: boolean;
  }) => {
    const previousProduct = isRecommended && addToCartProduct ? addToCartProduct : null;
    const _variant = variant ?? castToVariantOrNull(product.defaultVariant);

    dispatch(setSubmitted(false));
    dispatch(setSubmitting(false));
    dispatch(setPreviousProduct(previousProduct));
    dispatch(setShowSlider(true));
    dispatch(setAddToCartProduct(product));
    dispatch(setAddToCartVariant(_variant));
    dispatch(setQuantity(computeMinimumQuantity({ choosenQuantity: quantity, product })));
    dispatch(setProductList(productList || null));

    if (isReservationEnabled(_variant))
      await updateAvailabilityCalendar({
        variant: _variant,
        quantity,
        showFirstAvailability: true,
      });
  };

  const getMaxQuantityAllowed = useCallback(
    (product: Product | null): number => {
      if (!product) return 0;
      if (!product?.customerBuyingLimit) return MAX_PRODUCT_QUANTITY_PER_CUSTOMER;
      if (isEdit) return product.customerBuyingLimit;

      const quantityInCart = getQuantityOfProductInCart(product, cart);
      if (quantityInCart >= product.customerBuyingLimit) return 0;

      return product.customerBuyingLimit - quantityInCart;
    },
    [cart, isEdit]
  );

  const canProductBeBought = useCallback(
    (product: Product | null): boolean => {
      if (!product) return false;
      return canProductBeAddedToCart(product, cart);
    },
    [cart]
  );

  const canBeAddedAutomaticallyToTheCart = useCallback(
    (product: Product | null): boolean => {
      if (!product) return false;
      return (
        product &&
        product.variants.length === 1 &&
        getMaxQuantityAllowed(product) === 1 &&
        canProductBeBought(product)
      );
    },
    [canProductBeBought, getMaxQuantityAllowed]
  );

  return {
    submitAddToCartContext,
    errorMessage,
    setErrorMessage,
    validateReserveNow,
    openSlideOver,
    canProductBeBought,
    canBeAddedAutomaticallyToTheCart,
    getMaxQuantityAllowed,
  };
};

export default useCart;
