import * as React from 'react';
import { Control } from './Control';

export function PreviousSlideControl(props: {
  index: number;
  currentSlide: number;
  onPreviousControlClick: () => void;
}) {
  const isFirstIndicator = props.index === 0;
  if (!isFirstIndicator) {
    return null;
  }

  return (
    <Control
      disabled={props.currentSlide === 0}
      onClick={props.onPreviousControlClick}
      testId={'prevControl'}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6 select-none"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#6E28FF"
        strokeWidth={3}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
      </svg>
    </Control>
  );
}
