import { Z_INDEX } from 'constants/common';
import { DagpakkerStickerLarge } from 'components/product/dagpakker-sticker-large';
import { DagpakkerStickerSmall } from 'components/product/dagpakker-sticker-small';
import { FC } from 'react';
import { DagpakkerDetails, productTag } from 'types/product';
import ProductLabel from 'components/product/ProductLabel';
import { ProductCardSizes } from 'types/productCard';
import { ProductBoxInfoPlacementTypes } from 'components/product/ProductBox';
import {
  PRODUCT_INFO_PLACEMENT_TYPES,
  productCardSizesWithSmallDagpakkerSticker,
} from 'lib/productCard/productCard';

interface Props {
  dagpakkerDetails: DagpakkerDetails | null;
  productTag: productTag | null;
  size: ProductCardSizes;
  productInfoPlacementType: ProductBoxInfoPlacementTypes;
}

export const ProductCardImageSticker: FC<Props> = ({
  dagpakkerDetails,
  productTag,
  size,
  productInfoPlacementType,
}) => {
  const smallStickerToSize =
    productCardSizesWithSmallDagpakkerSticker.get(productInfoPlacementType);
  const needsDagpakkerDecalS = (smallStickerToSize && smallStickerToSize.has(size)) || false;

  if (!dagpakkerDetails) {
    return productTag && productInfoPlacementType === PRODUCT_INFO_PLACEMENT_TYPES.UNDER_IMAGE ? (
      <div className={`absolute top-2 left-2 ${Z_INDEX.level20}`}>
        <ProductLabel tag={productTag} />
      </div>
    ) : null;
  }

  return (
    <>
      {!needsDagpakkerDecalS && (
        <div className={`absolute top-[3%] left-[3%] right-[50%] ${Z_INDEX.level5}`}>
          <DagpakkerStickerLarge />
        </div>
      )}
      {needsDagpakkerDecalS && (
        <div className={`absolute top-2 left-2 ${Z_INDEX.level20}`}>
          <DagpakkerStickerSmall />
        </div>
      )}
    </>
  );
};
