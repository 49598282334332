import { BackendError, Cart, CartReservation } from 'types/types';
import cartApi from 'api/cart';
import { TFunction } from 'i18next';

interface handleEditCartItemOpts {
  cart: Cart | null;
  authToken: string | null;
  orderItemId: number;
  quantity: number;
  reservation?: CartReservation | null;
  setCart: (value: Cart | null) => void;
  t: TFunction;
}

export default async function handleEditCartItem({
  cart,
  authToken,
  orderItemId,
  quantity,
  reservation,
  setCart,
  t,
}: handleEditCartItemOpts) {
  // make sure we have a valid cart
  if (!cart?.tokenValue) cart = await cartApi.fetchPickUpCart(authToken);

  try {
    const updatedCart = await cartApi.updateCartItem({
      authToken,
      cartToken: cart?.tokenValue ?? '',
      cartItemId: orderItemId,
      quantity,
      reservation,
    });
    await setCart(updatedCart);
  } catch (e) {
    if (cart) cart.isLoading = false;
    await setCart(cart);

    let errorMessage = t('app.ui.error_body');
    if (e instanceof BackendError && e.violations.length > 0) {
      errorMessage = e.violations[0].message;
    }

    throw errorMessage;
  }
}
