import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { Item } from 'types/ui';
import { classNames } from 'lib/classes';
import SelectDropdownOption from './SelectDropdownOption';
import { Z_INDEX } from 'constants/common';
import isEqual from 'lodash.isequal';

export default function SelectDropDown<T>({
  title,
  value,
  onChange,
  options,
  optionsDivider,
  className,
}: {
  title?: string;
  value: Item<T>;
  onChange: (value: Item<T>) => void;
  options: Item<T>[];
  optionsDivider?: boolean;
  className?: string;
}) {
  return (
    <Listbox
      value={value}
      onChange={(e: Item<T>) => (e.disabled === true ? null : onChange(e))}
      data-aq={'dropdownList'}
    >
      {({ open }) => (
        <>
          {title && (
            <Listbox.Label className="block text-sm font-medium text-gray-700">
              {title}
            </Listbox.Label>
          )}
          <div className={`relative mt-2 ${className}`}>
            <Listbox.Button
              data-testid="selectedOption"
              className="relative w-full py-1 px-1 text-left cursor-default outline-none focus:outline-none sm:text-sm"
            >
              <SelectDropdownOption option={value} isHeader={true} isOpen={open} />
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={classNames(
                  `absolute ${Z_INDEX.level30} w-full py-1 mt-2 overflow-auto text-base bg-white rounded-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`,
                  optionsDivider ? 'divide-y divide-gray-200' : ''
                )}
              >
                {options.map((option, index) => (
                  <Listbox.Option
                    key={`option${index}`}
                    className={({ active }) =>
                      classNames(
                        'text-gray-900',
                        'cursor-pointer select-none relative py-2 pl-3 pr-3'
                      )
                    }
                    value={option}
                    data-aq={'dropdownItem'}
                  >
                    {({ active }) => (
                      <SelectDropdownOption
                        option={option}
                        active={active}
                        selected={isEqual(option.value, value.value)}
                      />
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
