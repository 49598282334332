import API_ENDPOINTS from 'constants/routes/api';
import { doPost, handleResponseErrorIfAny, resolveApiUrl } from 'lib/api';
import { transformCartJson } from 'lib/transforms';
import { AddToCartPayload, Cart, NotFoundError } from 'types/types';

export async function addProductToCart(payload: AddToCartPayload): Promise<Cart | null> {
  const endpoint = resolveApiUrl(API_ENDPOINTS.CartItems(payload.cartId));
  const res = await doPost(
    endpoint,
    {
      productVariant: '/api/v2/shop/product-variants/' + payload.item.productVariantCode,
      quantity: payload.item.quantity,
      reservation: payload.item.reservation,
      recommendation: payload.item.recommendation,
    },
    {
      headers: payload.authToken ? { 'X-AUTH-TOKEN': payload.authToken } : {},
    }
  );

  try {
    const result = await handleResponseErrorIfAny(res);
    const cart = transformCartJson(result);

    return {
      ...cart,
      isLoading: false,
    };
  } catch (e) {
    if (e instanceof NotFoundError) return null;

    throw e;
  }
}

export default addProductToCart;
