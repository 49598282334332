import { useTranslation } from 'react-i18next';
import { QUANTITY_LABEL } from 'constants/enums';

export interface AbbreviationProps {
  abbreviationAmount: number | undefined;
  quantityLabel: (typeof QUANTITY_LABEL)[keyof typeof QUANTITY_LABEL];
}

export default function Abbreviation({ abbreviationAmount, quantityLabel }: AbbreviationProps) {
  const { t } = useTranslation();

  return (
    <span className="text-purple text-xs whitespace-nowrap">
      {t(`app.ui.per_unit.${quantityLabel}.abbreviation`, {
        amount: abbreviationAmount,
      })}
    </span>
  );
}
