import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { PRODUCT_CARD_SIZES } from 'constants/productCard/productCard';
import { useResize } from 'hooks/resize/useResize';
import { ProductCardSizes } from 'types/productCard';
import { getSizeLabel } from 'lib/productCard/productCard';

type ReturnTypes<T> = {
  ref: RefObject<T>;
  size: ProductCardSizes;
};

type Props = {
  initialRenderSize?: ProductCardSizes;
};

export const useProductBoxSize = <T extends HTMLElement>({
  initialRenderSize = PRODUCT_CARD_SIZES.XS,
}: Props): ReturnTypes<T> => {
  const ref = useRef<T>(null);
  const resizeContext = useResize();
  const [size, setSize] = useState<ProductCardSizes>(initialRenderSize);
  const hasRegistered = useRef(false);

  const updateSize = useCallback(() => {
    if (!ref.current) {
      return;
    }

    const newSize = getSizeLabel(ref.current?.offsetWidth || 0);

    if (size !== newSize) {
      setSize(newSize);
    }
  }, [size]);

  useEffect(() => {
    resizeContext.registerResizeFunction(updateSize);
    hasRegistered.current = true;

    updateSize();

    return () => {
      resizeContext.unregisterResizeFunction(updateSize);
    };
  }, [resizeContext, updateSize]);

  return { ref, size };
};
