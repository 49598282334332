import { HtmlTransformer } from 'lib/HtmlTransformers/HtmlTransformerChainHandler';
import React, { ReactNode } from 'react';
import { Node } from 'lib/HtmlTransformers/Transform';

export default class H2Transformer implements HtmlTransformer {
  transform(node: Node, dataOrChildren: ReactNode, index: number): React.ReactElement {
    return (
      <h2 key={`h2${index}`} className="py-4 text-xl font-semibold">
        {dataOrChildren}
      </h2>
    );
  }

  doesTransform(node: Node): boolean {
    return node.type === 'tag' && node.name === 'h2';
  }
}
