import * as React from 'react';
import { Fragment, useCallback, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import SubmittedStep from './cartSlideOver/SubmittedStep';
import AddToCartStep from './cartSlideOver/AddToCartStep';
import { useRouter } from 'next/router';
import { Z_INDEX } from 'constants/common';
import { useAppDispatch, useAppSelector } from 'redux/appStore';
import {
  getAddToCartContext,
  resetAddToCart,
  setAddToCartProduct,
  setPreviousProduct,
  setShowSlider,
  setSubmitted,
} from 'redux/cart/addToCart.slice';
import { resetReservation } from 'redux/cart/reservation.slice';

export default function CartSlider() {
  const dispatch = useAppDispatch();
  const { showSlider, previousProduct, product, submitted } = useAppSelector(getAddToCartContext);
  const { t } = useTranslation();
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      if (product && showSlider) {
        dispatch(setShowSlider(false));
        dispatch(resetReservation());
        dispatch(resetAddToCart());
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  });

  const setOpen = useCallback(
    (isOpen = true) => {
      if (isOpen && product) {
        dispatch(setShowSlider(true));
        dispatch(setSubmitted(false));
        return;
      }
      dispatch(setShowSlider(false));
      dispatch(resetReservation());
      dispatch(resetAddToCart());
    },
    [dispatch, product]
  );

  const goBack = useCallback(() => {
    if (previousProduct) {
      dispatch(setAddToCartProduct(previousProduct));
      dispatch(setPreviousProduct(null));
      dispatch(setSubmitted(true));
    } else {
      setOpen(false);
    }
  }, [dispatch, previousProduct, setOpen]);

  const getStepComponent = useCallback(() => {
    if (!product) return null;
    if (submitted) return <SubmittedStep onClose={goBack} />;

    return <AddToCartStep onClose={goBack} />;
  }, [goBack, product, submitted]);

  return (
    <Transition.Root show={showSlider} as={Fragment}>
      <Dialog as="div" className={`relative ${Z_INDEX.level40}`} onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div
              className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10"
              data-testid={'cartSliderContainer'}
            >
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen sm:max-w-sm lg:max-w-lg md:max-w-md xl:max-w-md max-w-xs">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 mr-10 flex pt-4 pr-2 sm:-ml-10 sm:pr-4 z-45">
                      <button
                        type="button"
                        className="rounded-md text-black hover:text-orange focus:outline-none focus:ring-2 focus:ring-white"
                        data-testid={'cartSliderCloseBtn'}
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl cartSlider__content">
                    <div className="p-4 sm:px-6">
                      <Dialog.Title className="text-lg font-bold text-gray-900">
                        {t(
                          submitted
                            ? 'app.ui.cart_slider.submitted_title'
                            : 'app.ui.cart_slider.title'
                        )}
                      </Dialog.Title>
                    </div>

                    {getStepComponent()}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
