import React from 'react';
import { Node } from 'lib/HtmlTransformers/Transform';

export interface NodeSanitizers {
  sanitize(node: Node): Node;
  needsSanitizing(node: Node): boolean;
}

export default class NodeSanitizerChainHandler {
  private readonly sanitizers: NodeSanitizers[];

  constructor(sanitizers: NodeSanitizers[]) {
    this.sanitizers = sanitizers;
  }

  handle(node: Node): Node {
    let sanitizedNode = node;
    for (const sanitizer of this.sanitizers) {
      if (!sanitizer.needsSanitizing(node)) {
        continue;
      }

      sanitizedNode = sanitizer.sanitize(node);
    }

    return sanitizedNode;
  }
}
