import { MAX_PRODUCT_QUANTITY_PER_CUSTOMER } from 'constants/product';

export const determineMinQuantity = (
  quantityOfAllVariants: number,
  quantityOfVariant: number,
  minimumRequiredQuantity: number
): number => {
  // Calculate the total quantity excluding the current variant
  const totalOfOtherVariants = quantityOfAllVariants - quantityOfVariant;

  // Determine the minimum value for the current variant
  const minimumForThisVariant = Math.max(minimumRequiredQuantity - totalOfOtherVariants, 0);

  // Ensure the value does not exceed the overall minimum required
  return Math.min(minimumForThisVariant, minimumRequiredQuantity);
};

export const determineMaxQuantity = (
  quantityOfAllVariants: number,
  quantityOfVariant: number,
  productBuyingLimit?: number
): number => {
  if (!productBuyingLimit) {
    return MAX_PRODUCT_QUANTITY_PER_CUSTOMER;
  }

  // Calculate the total quantity excluding the current variant
  const totalOfOtherVariants = quantityOfAllVariants - quantityOfVariant;

  // Determine the maximum value for the current variant
  const maxForThisVariant = productBuyingLimit - totalOfOtherVariants;

  // Ensure the value does not fall below zero
  return Math.max(maxForThisVariant, 0);
};
