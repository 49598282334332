import { TimeParts } from 'hooks/timer/useCountDown';

export const isTimeUnitFinished = (value: string, higherUnits: string[]) => {
  return value === '00' && higherUnits.every((unit) => unit === '00');
};

export const calculateTimeLeft = (end: Date): TimeParts => {
  const difference = +new Date(end) - +new Date();
  let timeLeft = {
    hours: '00',
    minutes: '00',
    seconds: '00',
  };

  if (difference > 0) {
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    timeLeft = {
      hours: hours < 10 ? `0${hours}` : hours.toString(),
      minutes: minutes < 10 ? `0${minutes}` : minutes.toString(),
      seconds: seconds < 10 ? `0${seconds}` : seconds.toString(),
    };
  }

  return timeLeft;
};
